/* =======================================================
Website: aptihealth
URL: https://www.aptihealth.com
Company : Rugged Peak, LLC
Author: Matthew J. Stellato
Author URI: https://www.ruggedpeak.co/
================================================================== */

/*  Reset | http://meyerweb.com/eric/tools/css/reset/
---------------------------------------------------------------------- */
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}
.clear:after,
.clear:before,
.clearfix:after {
  content: "";
  display: block;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.clearfix:after {
  visibility: hidden;
  clear: both;
  height: 0;
}
.clear:after {
  clear: both;
}
.clear {
  zoom: 1;
}
:focus {
  outline: 0;
}
.hidden {
  display: none;
}

$green: #0cd0a7;
$green-dark: #148d73;
$purple: #5b57dc;
$purple-dark: #201d88;
$blue: #08202c;
$body: #6c757d;

/*  Getting Organized // Base Structure & Styles
---------------------------------------------------------- */
html {
  background: #08202c;
}

body {
  background: #fff;
  color: $body;
  font-size: 17px;
  line-height: 25px;
  font-family: "Helvetica", sans-serif;
  font-style: normal;
  font-weight: 500;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.wistia_popover_mode {
    overflow: hidden;
  }
}

::selection {
  background: $green;
  color: #fff;
}

p {
  padding: 0 0 20px 0;
}

a {
  color: $green;
  text-decoration: none;
  transition: all 0.2s linear;
  cursor: pointer;

  &:hover {
    color: $purple;
  }
}

.wrap {
  max-width: 1190px;
  margin: 0 auto;
  padding: 20px;
  position: relative;

  &.sm {
    max-width: 960px;
  }

  &:after {
    visibility: hidden;
    display: block;
    content: "";
    clear: both;
    height: 0;
  }
}

.text-center {
  text-align: center;
}

sup {
  font-size: 75%;
  position: relative;
  top: -4px;
  left: 3px;
  display: inline-block;
  margin-right: 1px;
}

.link {
  color: $purple;
  text-decoration: underline;
  font-weight: bold;
}

/*  Header
---------------------------------------------------------- */
.scroll {
  header {
    background: #fff;
    border-bottom: 1px solid #e0e0e0;

    &.client {
      background: #fff;
      border-bottom: 1px solid #e0e0e0;
      box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.05);

      .logo {
        width: auto;
        overflow: hidden;
        span {
          background-repeat: no-repeat;
          background-position: top left;
          background-size: 100%;

          img {
            opacity: 0;
          }
        }
      }
    }

    .logo {
      width: 220px;
      overflow: hidden;
      span {
        background: url("./assets/Apti_Logo_Navy.svg") no-repeat top left;
        background-size: 220px;

        img {
          opacity: 0;
        }
      }
    }

    nav {
      li {
        a {
          color: $body;
          &:hover {
            color: #000;
          }
        }
      }
    }
  }
  .login {
    span {
      border: 1px solid $body;
    }
  }
  .btnNav {
    span {
      background: $green;
      color: #fff;
    }
    &:hover {
      opacity: 1;
      span {
        background: $green-dark;
        border: 1px solid $green-dark;
      }
    }
  }
}

.introHead,
.heading {
  max-width: 885px;
  margin: 0 auto;
}

.introHead {
  font-size: 28px;
  padding-bottom: 10px;
}

.subhead {
  font-size: 32px;
}

.heading {
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 1.5;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  background: rgba(8, 32, 44, 0.85);
  border-bottom: 1px solid transparent;
  font-size: 18px;
  line-height: normal;
  z-index: 8000;
  transition: all 0.2s linear;

  // New Client
  &.client {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    background: #fff;
    border-bottom: 1px solid #e0e0e0;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.05);
    font-size: 18px;
    line-height: normal;
    z-index: 8000;
    transition: all 0.2s linear;
    height: 77px;
    box-sizing: border-box;

    &.partner {
      .wrap {
        padding: 13.5px 20px;
      }
      &.affu {
        .wrap {
          padding: 8px 20px;
        }
      }
      &.nyspffa {
        .wrap {
          padding: 6px 20px;
        }
      }
      &.fidelis,
      &.albanymed,
      &.sphp,
      &.amcemployees {
        .wrap {
          padding: 3px 20px;
        }
      }
    }

    .logo {
      span {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100%;

        img {
          opacity: 0;
        }
      }
      &.partner {
        top: -2px;
        span {
          width: 340px;
          img {
            opacity: 0;
          }
        }
        &.affu {
          span {
            width: 295px;
          }
        }
        &.nyspffa {
          span {
            width: 330px;
          }
        }
        &.albanymed,
        &.amcemployees {
          span {
            width: 365px;
          }
        }
        &.fidelis,
        &.sphp {
          span {
            width: 425px;
          }
        }
      }
    }

    &.loaded {
      h5 {
        width: calc(100% - 200px);
      }
      button.btn,
      a.btn {
        top: 50%;
        transform: translateY(-50%);
        opacity: 1;
      }
    }
    &.cta {
      .btn {
        transform: translateY(0);
        opacity: 1;
      }
    }

    .btn {
      position: absolute;
      right: 20px;
      text-align: center;
      top: 0;
      padding: 12px 45px;
      transform: translateY(-100%);
    }
  }

  nav {
    position: absolute;
    top: 0;
    right: 20px;
    li {
      float: left;
      margin-left: 30px;
      position: relative;
      transition: linear 0.3s all;

      &.login {
        margin-left: 15px;
      }

      // Stacked Button
      &.btnNav {
        padding: 30px 0 28px;
        cursor: pointer;
        span {
          border: 1px solid $green;
          color: $green;
          transition: ease 0.3s all;
          padding: 3px 0 5px;
          display: block;
          strong {
            font-weight: normal;
            font-size: 15px;
            display: block;
            text-align: center;
            position: relative;
            &:after {
              content: "";
              position: relative;
              font-size: 8px;
              width: 7px;
              height: 7px;
              top: -3px;
              margin-left: 8px;
              pointer-events: none;
              border-left: 1px solid;
              border-bottom: 1px solid;
              transform: rotate(-45deg);
              display: inline-block;
            }
          }
        }
        &:hover {
          span {
            background: rgba(12, 208, 167, 0.75);
            color: #fff;
            padding-bottom: 0;
            overflow: hidden;
          }
          &:after {
            display: none;
          }
        }

        // Button Subnav
        &:hover {
          ul {
            height: 87px;
            margin: 6px 0 0;
            border: none;
            background: rgba(12, 208, 167, 0.85);
            padding: 1px 0 0;
          }
        }

        ul {
          position: static;
          width: auto;
          top: auto;
          background: none;
          margin: 0;

          li {
            background: none;
            a {
              padding: 7px 15px;
              color: #fff;
              &:hover {
                background: $green-dark;
                color: #fff;
              }
            }
          }
        }
      }

      a {
        display: block;
        font-size: 15px;
        color: #fff;
        padding: 34px 0;

        &:hover {
          color: rgba(255, 255, 255, 0.65);
        }

        &.active {
          color: $green;
          font-weight: bold;
          position: relative;
          &:after {
            height: 4px;
            width: 100%;
            content: "";
            display: block;
            position: absolute;
            bottom: 1px;
            left: 0;
            background: $green;
          }
        }
      }
      &:hover {
        ul {
          height: 85px;
          padding: 5px 0;
          border: 1px solid #d1d1d1;
          &.one {
            height: 46px;
          }
        }
      }

      ul {
        position: absolute;
        background: #fff;
        overflow: hidden;
        top: 82px;
        height: 0;
        width: 175px;
        transition: linear 0.3s all;
        margin-left: -50px;
        box-sizing: border-box;

        &.two {
          margin-left: -52px;
        }

        &.sm {
          width: 150px;
          margin-left: -20px;
        }

        li {
          float: none;
          margin: 0 !important;
          a {
            padding: 0;
            color: #000;
            font-size: 13px;
            padding: 10px 15px;
            display: block;
            text-align: center;

            &:hover {
              color: $green;
            }
            &:after {
              display: none !important;
            }
          }
        }
      }
    }
  }
}

.login,
.btnNav {
  span {
    padding: 3px 10px 5px;
    border: 1px solid #fff;
    border-radius: 4px;
    font-weight: 500;
  }
}

.logo {
  display: block;
  float: left;
  position: relative;
  top: 3px;
  span {
    display: block;
    width: 220px;
    float: left;
  }
  img {
    display: block;
    position: relative;
    width: 100%;
  }
  strong {
    display: block;
    margin-left: 235px;
    font-family: proxima-nova, sans-serif;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0.26px;
    font-size: 13px;
    padding-top: 3px;
    width: 175px;
    color: $green;
  }
}

/*  Banner
---------------------------------------------------------- */

#banner {
  text-align: left;
  padding-top: 160px;
  background: #000;
  background-size: 100%;
  box-sizing: border-box;
  position: relative;
  height: 550px;

  &.client {
    background-color: #fff;
    height: 500px;
    h1 {
      color: $green;
    }

    &.sphp {
      margin-top: 70px;
      padding-top: 40px;
      h1 {
        color: $green;
        font-weight: 900;
        padding-bottom: 20px;
        font-size: 70px;
        line-height: 70px;
      }
      h3 {
        font-size: 45px;
        color: $blue;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 50%;
        bottom: 0;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 0) 100%
        );
        transition: ease 0.3s all;
        z-index: 1;
      }
    }

    &.amcemployees {
      margin-top: 77px;
      background-image: url("./assets/banner-hcp.jpg");
      background-size: cover;
      padding: 100px 0 0;
      background-position: top center;
      height: 420px;
      &:after {
        display: none;
      }
      h1 {
        padding: 0;
      }
    }

    // Banner Slider
    .slick-slider {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .slick-list,
      .slick-track {
        height: 100%;
      }
      &.slick-initialized .slick-slide {
        height: 100%;
        div {
          height: 100%;
          .slide {
            background-color: #fff;
            background-repeat: no-repeat;
            background-position: top center;
            background-size: auto 100%;
          }
          #bg01 {
            background-image: url("./assets/banner-clients-01.jpg");
          }
          #bg02 {
            background-image: url("./assets/banner-clients-02.jpg");
          }
          #bg03 {
            background-image: url("./assets/banner-clients-03.jpg");
          }
          #bg04 {
            background-image: url("./assets/banner-clients-04.jpg");
          }
          #bg05 {
            background-image: url("./assets/banner-clients-05.jpg");
          }
          #bg06 {
            background-image: url("./assets/banner-clients-06.jpg");
          }
          #bg07 {
            background-image: url("./assets/banner-clients-07.jpg");
          }
          #bg-hero-01 {
            background-color: #efefef;
            background-image: url("./assets/Healthcare_Hero_01.jpg");
          }
          #bg-hero-02 {
            background-color: #efefef;
            background-image: url("./assets/Healthcare_Hero_02.jpg");
          }
          #bg-hero-03 {
            background-color: #efefef;
            background-image: url("./assets/Healthcare_Hero_03.jpg");
          }
          #bg-hero-04 {
            background-color: #efefef;
            background-image: url("./assets/Healthcare_Hero_04.jpg");
          }
          #roi-header {
            background-image: url("./assets/banner-roi.jpg");
          }
        }
      }
    }

    &.video {
      padding-top: 80px;
      height: auto;
      .wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        h1 {
          width: 40%;
          font-size: 42px;
          line-height: 52px;
          padding-right: 30px;
          box-sizing: border-box;
        }
        .vid {
          width: 60%;
          .wistia_click_to_play {
            width: auto !important;
            height: auto !important;
          }
          a {
            display: block;
            border: 1px solid #e1e1e1;
            position: relative;
            &:before {
              content: "";
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, 0.3);
              opacity: 0;
              transition: ease 0.3s all;
            }
            &:hover {
              &:before {
                opacity: 1;
              }
            }
          }
          img {
            width: 100%;
            display: block;
          }
        }

        @media (max-width: 960px) {
          h1 {
            font-size: 32px;
            line-height: 38px;
          }
        }
      }
      @media (max-width: 725px) {
        padding-top: 60px;
        margin-top: 0;

        .wrap {
          h1 {
            width: 100%;
            padding: 0 0 10px;
          }

          .vid {
            width: 100%;
          }
        }
      }

      @media (max-width: 550px) {
        .wrap {
          .vid {
            margin-bottom: 30px;
          }
        }
      }
    }
  }

  // Video Player
  .rh5v-DefaultPlayer_component {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 10;
    }

    video {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover;
    }

    .rh5v-DefaultPlayer_controls {
      display: none;
    }
  }

  .wrap {
    position: relative;
    z-index: 20;
  }

  h1 {
    font-family: proxima-nova, sans-serif;
    font-weight: 600;
    color: #fff;
    font-size: 54px;
    line-height: 66px;
    letter-spacing: 1.08px;
    padding: 15px 0 35px;
    span {
      display: inline-block;
      background: $purple;
      border-radius: 4px;
      padding: 0 10px;
      margin-left: -10px;
    }
  }

  h3 {
    color: $green;
    padding-bottom: 0;
    font-size: 20px;
    padding-bottom: 10px;
    font-weight: 500;
  }

  .btn {
    margin-right: 20px;
  }

  // Secondary Banners
  &.secondary {
    height: auto;
    padding: 175px 0 20px;
    background-color: #e1e1e1;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;

    &.page-provider {
      background-image: url("./assets/bg-banner-providers.jpg");
    }
    &.page-primary {
      background-image: url("./assets/bg-banner-primary.jpg");
    }
    &.page-hospitals {
      background-image: url("./assets/provider-photo-hospitals.jpg");
    }
    &.page-behavioral {
      background-image: url("./assets/bg-banner-individuals.jpg");
    }

    &.page-behavioral-light-up {
      background-image: url("./assets/bg-banner-individuals.jpg");
    }

    &.page-employers {
      background-image: url("./assets/bg-banner-employers.jpg");
    }
    &.page-colleges {
      background-image: url("./assets/bg-banner-colleges.jpg");
    }

    &.page-about {
      background-image: url("./assets/bg-banner-about.jpg");
    }
    &.page-careers {
      background-image: url("./assets/bg-banner-careers.jpg");
    }
    &.page-individuals {
      background-image: url("./assets/bg-banner-individuals.jpg");
    }
    &.page-healthplans {
      background-image: url("./assets/bg-banner-health-plans.jpg");
    }
    &.page-contact {
      background-image: url("./assets/bg-banner-contact.jpg");
    }
    &.page-news {
      background-image: url("./assets/bg-banner-news.jpg");
    }

    &.page-provider {
      background: rgba(244, 244, 244, 1);
      padding: 44px 0 0;
      &:after {
        display: none;
      }
      h1 {
        display: none;
      }
    }

    &.page-careers h1 {
      width: 60%;
    }

    &.pcp {
      background-image: url("./assets/banner-pcp.jpg");
      padding: 175px 0 140px;
      &:after {
        background-image: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.2) 0%,
          rgba(0, 0, 0, 0) 28%,
          rgba(0, 0, 0, 0.6) 54%,
          rgba(0, 0, 0, 0.6) 100%
        );
      }
      h1 {
        float: right;
        width: 50%;
        font-size: 42px;
        line-height: 46px;
        padding-left: 30px;
        box-sizing: border-box;
      }
    }

    &.hcp {
      margin-top: 77px;
      background-image: url("./assets/banner-hcp.jpg");
      padding: 80px 0 80px;
      background-position: top center;
      &:after {
        display: none;
      }
      .wrap {
        div {
          width: 50%;
          h1 {
            color: #0e3c51;
            width: 100%;
            padding-bottom: 20px;
            font-size: 45px;
            line-height: 50px;
          }

          @media (max-width: 825px) {
            width: 60%;
            h1 {
              font-size: 38px;
              line-height: 40px;
            }
          }

          @media (max-width: 825px) {
            h1 {
              font-size: 28px;
              line-height: 32px;
            }
          }
        }
      }
    }

    &:after {
      position: absolute;
      content: "";
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      //background: rgba(0,0,0,.7);

      background: transparent
        linear-gradient(270deg, #707070b4 0%, #383838c4 100%) 0% 0% no-repeat
        padding-box;
      mix-blend-mode: multiply;
    }

    h1 {
      font-family: proxima-nova, sans-serif;
      font-weight: bold;
      font-size: 38px;
      line-height: 42px;
      font-style: normal;
      letter-spacing: 0.68px;
      padding: 0;
      width: 54%;
    }
  }

  // New Banners 11/2022
  &.branded {
    background: #ece4d6;
    height: auto;
    padding-top: 80px;
    .wrap {
      display: flex;
      padding-bottom: 0;
      align-items: flex-end;
      .content {
        width: 60%;
        padding-bottom: 30px;
      }
      figure {
        width: 40%;
        text-align: right;
        img {
          width: 90%;
          display: block;
          margin-left: auto;
        }
      }
    }
    h1 {
      color: #54565a;
      font-family: "Barlow", sans-serif;
      font-size: 70px;
      letter-spacing: -1px;
    }
    h3 {
      font-size: 30px;
      color: #54565a;
    }
    .btn {
      background: #5a6cb2;
      color: #fff;
      border-radius: 50px;
      padding: 16px 35px;
      margin: 30px 0;
      &:hover {
        background: #89ca8c;
      }
    }

    p {
      text-align: left;
      color: #54565a;
    }

    @media (max-width: 1075px) {
      h1 {
        font-size: 60px;
      }
      h3 {
        font-size: 24px;
      }
    }

    @media (max-width: 960px) {
      h1 {
        font-size: 44px;
        line-height: 46px;
        padding-bottom: 15px;
      }
      h3 {
        font-size: 20px;
      }
      .btn {
        margin: 15px 0;
      }
    }

    @media (max-width: 825px) {
      padding-bottom: 0;
      p {
        font-size: 14px;
      }
    }

    @media (max-width: 675px) {
      .wrap {
        flex-wrap: wrap;
        .content {
          width: 100%;
          padding: 0;
        }
        figure {
          width: 100%;
        }
      }
      h1 {
        font-size: 35px;
        line-height: 38px;
        padding-bottom: 15px;
        letter-spacing: 0;
      }
      .btn {
        width: 100%;
        box-sizing: border-box;
        text-align: center;
      }
    }
  }
}

// RPI Header
#header.rpiteam {
  .wrap {
    padding: 20px 20px;
  }
  .btn {
    background: #5a6cb2;
    color: #fff;
    border-radius: 50px;
    padding: 8px 25px;
    &:hover {
      background: #89ca8c;
    }
  }
  .logo.rpiteam {
    display: flex;
    span {
      display: inline-flex;
      height: 40px;
      width: auto;
      padding: 0 30px;
      border-right: 2px solid #ece4d6;
      align-items: center;
      background: transparent !important;
      img {
        opacity: 1;
        height: 100%;
        width: auto;
      }
      &:first-child {
        padding-left: 0;
        img {
          height: 95%;
        }
      }
      &:last-child {
        border: 0;
        img {
          height: 85%;
        }
      }
    }
  }

  @media (max-width: 825px) {
    height: 67px !important;
    .logo.rpiteam {
      width: auto;
      top: 0;
      span {
        height: 30px;
      }
    }
  }

  @media (max-width: 600px) {
    height: 57px !important;

    .wrap {
      padding: 15px;
    }

    .logo.rpiteam {
      span {
        height: 26px;
        padding: 0 10px;
      }
    }
  }

  @media (max-width: 500px) {
    .btn {
      display: none;
    }
  }
}

.headline {
  font-size: 22px;
  line-height: 32px;
  font-weight: normal;
  text-align: center;
  margin: 0 auto 30px;
  max-width: 1100px;
  color: #6c757d;

  &.left {
    font-size: 20px;
    max-width: 900px;
    text-align: left;
    margin: 0 0 30px;
    padding: 0;

    @media (max-width: 725px) {
      font-size: inherit;
      line-height: inherit;
    }

    &.full {
      max-width: none;
    }
  }
}

.results {
  img {
    display: block;
    margin: 0 auto;
    height: 150px;
  }
  h3 {
    text-align: center;
    padding: 30px 0;
  }
  .col {
    padding: 0 30px;
  }
  .disc {
    padding-bottom: 40px;
    li {
      padding-bottom: 15px;
    }
  }
}

.timeline {
  max-width: 560px;
  margin: 30px auto;
}

.linkedInTracking {
  display: none;
  height: 1px;
  width: 1px;
}

.single {
  padding-top: 120px;
  padding-bottom: 80px;

  h1,
  .h1 {
    text-align: center;
    font-weight: 700;
    font-size: 40px;
    line-height: normal;
    padding-bottom: 30px;
    margin-bottom: 50px;
    position: relative;
    &:after {
      content: "";
      display: block;
      width: 100px;
      height: 2px;
      background: #e1e1e1;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -50px;
    }
  }
  h3 {
    font-size: 22px;
    color: #000;
    padding-top: 15px;
    padding-bottom: 10px;
  }

  h2 {
    font-size: 26px;
    font-weight: 800;
    color: $blue;
    margin: 0;
    padding: 20px 0 5px;
    &.h1 {
      color: #6c757d;
      margin-top: 90px;
    }
  }

  p {
    padding-bottom: 10px;
    overflow-wrap: break-word;
    strong {
      color: $blue;
      margin-top: 20px;
      display: block;
    }
  }

  ul.disc {
    li {
      padding-bottom: 0;
      .sublist {
        list-style: disc;
        padding: 10px 0 0 30px;
        li {
          padding: 0;
        }
      }
    }
  }

  &.privacy {
    strong {
      display: inline-block;
      margin: 0;
    }
    b {
      color: #666;
    }
    p {
      padding-bottom: 20px;
    }
    ol.alpha {
      list-style-type: lower-alpha;
      padding: 0 0 0 30px;
      li {
        padding-bottom: 15px;
      }
    }
    ul.disc {
      li {
        padding-bottom: 15px;
      }
      &.nopad {
        padding-bottom: 15px;
        li {
          padding: 0;
        }
      }
    }
    h6 {
      text-align: left;
    }
    &.terms {
      h2 {
        display: inline-block;
        border-bottom: 1px solid #aaa;
        padding-bottom: 0px;
        margin-bottom: 20px;
        line-height: normal;
      }
      h5 {
        color: $blue;
        padding-bottom: 20px;
      }
      h6 {
        text-align: left;
        padding: 20px 0 10px;
      }
    }
  }

  &.post {
    padding-bottom: 0;
    p {
      padding-bottom: 25px;
    }

    h3 {
      padding-bottom: 30px;
    }

    .postHeading {
      display: block;
      font-size: 18px;
      line-height: 26px;
    }

    p {
      a {
        text-decoration: underline;
        &.btn {
          text-decoration: none;
        }
      }
    }

    &.grey {
      padding: 20px 0 0;
      font-size: 16px;
      line-height: 20px;

      a {
        color: $purple;
        text-decoration: underline;
      }
    }

    h1 {
      text-align: left;
      margin: 0;
      padding: 0 0 10px;
      &:after {
        display: none;
      }
    }
    .date {
      font-size: 14px;
      color: #999;
      display: block;
      padding-bottom: 10px;
      font-weight: 600;
    }
    h4 {
      color: $green;
      font-weight: 700;
      padding: 10px 0 5px;
      text-align: left;
      font-size: 22px;
      &:first-child {
        padding-top: 0;
      }
    }

    strong {
      margin: 0;
      display: inline;
    }

    .row {
      padding: 0 0 15px;
      margin: 0 0 15px;
      border-bottom: 1px solid #f1f1f1;
      overflow: hidden;
      &.first {
        border-top: 1px solid #f1f1f1;
        padding: 15px 0;
      }
      &.last {
        margin-bottom: 30px;
      }

      h4 {
        float: left;
        width: 120px;
        font-size: 18px;
        line-height: 26px;

        @media (max-width: 500px) {
          width: 90px;
          font-size: 14px;
          line-height: 23px;
        }
      }

      h5 {
        padding-bottom: 5px;
        font-size: 14px;
        color: #999;
      }

      ul {
        &.first {
          padding-bottom: 15px;
        }
        strong {
          color: #000;
        }
      }

      p {
        padding: 0;
      }

      div {
        margin-left: 120px;
        @media (max-width: 500px) {
          margin-left: 90px;
          ul {
            padding-left: 20px;
            &.first {
              padding-bottom: 10px;
            }
            em {
              font-size: 12px;
              line-height: 16px;
              display: block;
              padding-bottom: 10px;
            }
          }
        }
      }
    }
  }
}

.intro {
  padding-top: 20px;
  padding-bottom: 30px;
  background: rgba(244, 244, 244, 1);
  &.full {
    .copy {
      width: 66%;
    }
  }

  .copy {
    float: left;
    width: 50%;
  }

  &.computer {
    img {
      margin: 0;
      width: 45%;
    }
  }
  img {
    display: block;
    float: right;
    margin: -140px 30px 0 0;
    width: 40%;
  }
  .vid {
    float: right;
    width: 44%;
    margin-top: -120px;
  }

  ul.disc {
    li {
      padding-bottom: 10px;
    }
  }

  // PCP
  &.pcp {
    background: #fff;
    .copy {
      width: 50%;
      padding-right: 40px;
      box-sizing: border-box;
      font-size: 18px;
      line-height: 26px;

      .btn {
        margin-top: 20px;
      }
    }
    .vid {
      margin: 0;
      width: 50%;
      box-sizing: border-box;
      padding-left: 30px;
    }
  }
}

.nobreak {
  white-space: nowrap;
}

.references {
  font-size: 11px;
  text-align: left;
  color: #999;
  line-height: 20px;
  p:last-child {
    padding: 0;
  }
  /*
  h6 {
    font-size:13px;
    margin: 0;
    text-align: left;
    padding: 0;
    line-height: normal;
    color:#777;    
  }
  ol {
    list-style: decimal;
    padding: 5px 0 0 15px;
  }
*/
}

.clientIntro {
  background: $purple;
  padding: 10px 0;

  .copy {
    margin-left: 250px;

    h4 {
      font-weight: 400;
      font-size: 23px;
      line-height: 30px;
      letter-spacing: 0.4px;
      color: #e8e8e8;
      padding: 0;
      text-align: left;
      strong {
        color: #fff;
      }
    }
  }

  .btn {
    float: left;
    text-align: center;
    font-size: 20px;
    padding: 18px 52px;
  }
}

.roiIntro {
  background: #5a6cb2;
  padding: 10px 0;

  .copy {
    @media (max-width: 500px) {
      margin-left: 10px;
      margin-top: 10px;
    }

    margin-left: 300px;

    h4 {
      font-family: "Helvetica Neue", sans-serif;
      font-weight: 800;
      font-size: 23px;
      line-height: 30px;
      letter-spacing: 0.4px;
      color: #ffffff;
      padding: 0;
      text-align: left;
      strong {
        color: #fff;
      }
    }
  }

  .btn2 {
    float: left;
    text-align: center;
    font-size: 20px;
    padding: 18px 52px;
  }
}

#single {
  banner {
    text-align: center;
  }
  section {
    .wrap {
      max-width: 960px;
    }
  }
}

ul.checks {
  padding-top: 10px;
  li {
    background: url("./assets/svg/icon-check.svg") no-repeat top 0 left 0;
    padding: 0 0 20px 38px;
    font-weight: 600;
    background-size: 23px;
  }
}

ul.disc {
  list-style: disc;
  padding-left: 30px;
  li {
    padding: 0 0 25px 0;
  }
}

.provider {
  display: flex;

  &.alt {
    div.col:first-child {
      order: 2;
      .content {
        float: left;
      }
    }
  }

  div.col {
    width: 50%;
    box-sizing: border-box;
    padding: 60px;

    .content {
      max-width: 540px;
      float: right;
    }

    &.photo {
      background-color: #e1e1e1;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      position: relative;
      min-height: 450px;
      &.primary {
        background-image: url("./assets/provider-photo-primary.jpg");
      }
      &.hospital {
        background-image: url("./assets/provider-photo-hospitals.jpg");
      }
      &.behavioral {
        background-image: url("./assets/provider-photo-behavioral.jpg");
        background-position: top center;
        &:after {
          opacity: 0.5;
        }
      }
      &.prescribers {
        background-image: url("./assets/provider-photo-prescriber.jpg");
        background-position: top center;
        &:after {
          opacity: 0.8;
        }
      }

      &.tool {
        background-image: url("./assets/provider-tool.jpg");
        background-position: top center;
      }

      &.individual {
        background-image: url("./assets/individual.jpg");
      }

      &.employers {
        background-image: url("./assets/enterprise-employers.jpg");
        background-position: top center;
      }

      &.employers02 {
        background-image: url("./assets/enterprise-employers-02.jpg");
      }

      &.college {
        background-image: url("./assets/enterprise-college.jpg");
      }

      &.college02 {
        background-image: url("./assets/enterprise-college-02.jpg");
      }

      &:after {
        position: absolute;
        content: "";
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        //background: transparent linear-gradient(180deg, rgba(0,0,0, 0.15) 0%, rgba(0,0,0, 0.5) 100%) 0% 0% no-repeat padding-box;
        background: transparent
          linear-gradient(
            270deg,
            rgba(112, 112, 112, 0.71) 0%,
            rgba(56, 56, 56, 0.77) 100%
          )
          0% 0% no-repeat padding-box;
        mix-blend-mode: multiply;
      }
    }
  }
}

.inline {
  text-align: center;
  margin-top: 20px;

  li {
    display: inline-block;
    padding: 0 10px;

    a {
      width: 205px;
      padding: 15px 0;
      box-sizing: border-box;
    }
  }
}

.tools {
  .two-col {
    align-items: center;
  }
  img {
    max-width: 545px;
    display: block;
    margin: 0 auto;
    width: 100%;
  }
  h3 {
    padding-top: 30px;
  }
}

#accordion {
  background: url("./assets/bg-accordion.jpg") repeat-x center bottom;
  min-height: 580px;
  overflow-x: hidden;
  h2 {
    text-align: center;
    padding: 20px 0 30px;
  }
  &.laptop {
    .wrap {
      padding-bottom: 100px;
    }
  }
}

.reference {
  font-size: 13px;
  line-height: normal;
  color: #999;
}

.cdphpPhoto {
  img {
    width: 100%;
  }

  margin-top: -10px;
  caption {
    display: block;
    font-style: italic;
    font-size: 16px;
    margin: 10px auto 50px;
    max-width: 800px;
    line-height: 24px;
    color: #999;
  }
}

#laptop {
  position: absolute;
  right: -80px;
  bottom: 20px;
  width: 800px;
  bottom: 0;

  .device {
    height: 450px;
    margin: 0 auto;
    background: url("./assets/gphc-laptop-base.png") no-repeat top center;
    background-size: 100%;

    /*
    background: url('./assets/svg/gphc-laptop-base.svg') no-repeat left bottom;
    background-size:800px;
    height: 450px;
    box-sizing: border-box;
    margin:0 auto;
*/
    position: relative;
    div.container {
      //       background: #FFF;
      /*
      position: absolute;
      top:0;
      left:0;
      right:0;
*/

      div {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transition: linear 0.2s all;
        text-align: center;
        opacity: 0;
        box-sizing: border-box;

        &.active-screen {
          opacity: 1;
        }

        &.s1 {
          background: url("./assets/screen-primary-01.png") no-repeat top center;
          background-size: 100%;
        }
        &.s2 {
          background: url("./assets/screen-primary-02.png") no-repeat top center;
          background-size: 100%;
        }
        &.s3 {
          background: url("./assets/screen-primary-03.png") no-repeat top center;
          background-size: 100%;
        }
        &.s4 {
          background: url("./assets/screen-primary-04.png") no-repeat top center;
          background-size: 100%;
        }
        &.s5 {
          background: url("./assets/screen-primary-05.png") no-repeat top center;
          background-size: 100%;
        }
      }

      &.behave {
        div {
          &.s1 {
            background: url("./assets/screen-behavioral-01.png") no-repeat top
              center;
            background-size: 100%;
          }
          &.s2 {
            background: url("./assets/screen-behavioral-02.png") no-repeat top
              center;
            background-size: 100%;
          }
          &.s3 {
            background: url("./assets/screen-behavioral-03.png") no-repeat top
              center;
            background-size: 100%;
          }
          &.s4 {
            background: url("./assets/screen-behavioral-04.png") no-repeat top
              center;
            background-size: 100%;
          }
          &.s5 {
            background: url("./assets/screen-behavioral-05.png") no-repeat top
              center;
            background-size: 100%;
          }
          &.s6 {
            background: url("./assets/screen-behavioral-06.png") no-repeat top
              center;
            background-size: 100%;
          }
        }
      }
    }
  }
}

#lightUp {
  #laptop {
    .device {
      background: url("./assets/screen-lightup-01.png") no-repeat top center;
      background-size: 100%;
      div.container {
        &.lightUp {
          div {
            background: transparent;
          }
        }
      }
    }
  }
}

#phone {
  margin-left: 55%;
  .device {
    background: url("./assets/svg/gphc-phone-base.svg") no-repeat center center;
    height: 550px;
    width: 295px;
    box-sizing: border-box;
    margin: 0 auto;
    position: relative;
    div.container {
      background: #fff;
      position: absolute;
      top: 62px;
      left: 37px;
      right: 37px;
      bottom: 66px;

      div {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transition: linear 0.2s all;
        text-align: center;
        opacity: 0;
        box-sizing: border-box;

        &.active-screen {
          opacity: 1;
        }
        &.s1 {
          background: url("./assets/screen-individual-01.jpg") no-repeat top
            center;
          background-size: 100%;
        }
        &.s2 {
          background: url("./assets/screen-individual-02.jpg") no-repeat top
            center;
          background-size: 100%;
        }
        &.s3 {
          background: url("./assets/screen-individual-03.jpg") no-repeat top
            center;
          background-size: 100%;
        }
        &.s4 {
          background: url("./assets/screen-individual-04.jpg") no-repeat top
            center;
          background-size: 100%;
        }
        &.s5 {
          background: url("./assets/screen-individual-05.jpg") no-repeat top
            center;
          background-size: 100%;
        }
      }
    }
  }
}

.accordion {
  padding-top: 20px;
  width: 45%;
  float: left;

  li {
    border-left: 4px solid #e1e1e1;
    margin-bottom: 30px;
    &.active {
      h4 {
        color: $green;
        font-weight: bold;
        border-color: $green;
      }
      p {
        display: block;
      }
    }
  }
  h4 {
    font-size: 24px;
    text-align: left;
    margin-left: -4px;
    border-left: 4px solid #bababa;
    padding-left: 20px;
    cursor: pointer;
    transition: linear 0.2s all;
    &:hover {
      color: $purple;
    }
    sup {
      font-size: 15px;
      top: -8px;
    }
  }
  p {
    padding: 0;
    padding-left: 20px;
    //     display: none;
  }

  .collapse {
    transition: 500ms cubic-bezier(0.4, 0, 0.2, 1);
    div {
      padding: 10px 2px;
    }
  }
}

.primary-stat {
  max-width: 400px;
  margin: 0 auto;
  display: block;
  padding: 30px 0 0 80px;

  &.multi {
    max-width: 300px;
    padding-top: 20px;
  }
}

.ref {
  margin-top: 60px;
  font-size: 13px;
  opacity: 0.7;
  line-height: normal;

  @media (max-width: 500px) {
    font-size: 12px;
    margin-top: 20px;
  }
}

.individual-stat {
  max-width: 400px;
  margin: 0 auto;
  display: block;
  padding: 0 0 0 80px;
}

/*

.animatePrice {
	transition: background-color 0.2s linear, opacity 0.2s linear;
	position: relative;

	&:before {
		position: absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		content:'';
		display: block;
		transition: linear 0.2s all;
		visibility: hidden;
	}

	&.active {
		&:before {
			background:rgba(255,255,255,.95);
			z-index: 20;
			visibility: visible;
		}
		&:after {
			overflow: hidden;
	    display: block;
	    position: absolute;
	    top: 30%;
	    vertical-align: bottom;
	    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
	    animation: ellipsis steps(4, end) 900ms infinite;
	    content: "\2026";
	    width: 0px;
	    left: 50%;
	    transform: translate(-50%,-50%);
	    color: $green;
			z-index: 25;
			font-weight: bold;
			font-size:35px;
			line-height: normal;
		}

		@keyframes ellipsis {
		  to {
		    width: 1.25em;
		  }
		}

		@-webkit-keyframes ellipsis {
		  to {
		    width: 1.25em;
		  }
		}

	}
}
*/

.container {
  overflow: hidden;
  display: flex;
  align-items: center;

  .copy {
    //     float:left;
    width: 50%;
    padding-right: 20px;
    text-align: left;
    font-size: 18px;
    line-height: 28px;
  }
  .content {
    padding-left: 20px;
    //     margin-left:35%;
  }
}

/*  Headings
---------------------------------------------------------- */
/*
h1 {
  font-size:64px;
  font-weight: 600;
  letter-spacing: -.04em;
  color:#4b4c4e;
  line-height: 70px;

	span.break {
		display: block;
	}
}
*/

h1,
h2,
h3,
h4 {
  font-family: proxima-nova, sans-serif;
}

h2 {
  font-size: 40px;
  font-weight: 700;
  //   letter-spacing: 0.68px;
  padding-bottom: 20px;
  line-height: 45px;
  color: $blue;

  &.divide {
    &:after {
      content: "";
      display: block;
      background: #e0e0e0;
      height: 2px;
      width: 100px;
      margin-top: 20px;
    }
  }

  &.pb30 {
    padding-bottom: 80px;
  }
}

h3 {
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  color: $blue;
  //   letter-spacing: 0.54px;
  font-size: 26px;
  padding-bottom: 20px;
  line-height: normal;
}

h4 {
  text-align: center;
  color: #4b4c4f;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
}

/*  Buttons
---------------------------------------------------------- */

.btn {
  color: #fff;
  background: $green;
  padding: 15px 35px;
  display: inline-block;
  border-radius: 4px;
  line-height: normal;
  letter-spacing: -0.015em;
  font-size: 18px;
  transition: all 0.2s linear;
  border: 0;
  outline: 0;
  cursor: pointer;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-style: normal;
  -webkit-appearance: none;

  &:hover {
    background: $green-dark;
    color: #fff;
  }

  &.lg {
    padding: 15px 65px;
  }

  &.sm {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 700;
  }

  &.alt {
    background: $purple;

    &:hover {
      background: $purple-dark;
    }
  }

  &.grey {
    color: #fff;
    background: #979ca1;

    &:hover {
      background: #c4c7ca;
    }
  }

  &.white {
    background: #fff;
    color: $green;
    border: 0;

    &:hover {
      background: $green-dark;
      color: #fff;
    }
  }

  &.back {
    background-image: url("./assets/svg/arrow.svg");
    background-repeat: no-repeat;
    background-position: left 15px top 50%;
    background-size: 16px;
    padding: 10px 20px 10px 40px;
  }
}

.btn2 {
  color: #54565a;
  background: #fcd672;
  padding: 15px 35px;
  display: inline-block;
  border-radius: 4px;
  line-height: normal;
  letter-spacing: -0.015em;
  font-size: 18px;
  transition: all 0.2s linear;
  border: 0;
  outline: 0;
  cursor: pointer;
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  -webkit-appearance: none;

  &:hover {
    background: #e5bb60;
    color: #fff;
  }

  &.lg {
    padding: 15px 65px;
  }

  &.sm {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 700;
  }

  &.alt {
    background: $purple;

    &:hover {
      background: $purple-dark;
    }
  }

  &.grey {
    color: #fff;
    background: #979ca1;

    &:hover {
      background: #c4c7ca;
    }
  }

  &.white {
    background: #fff;
    color: $green;
    border: 0;

    &:hover {
      background: $green-dark;
      color: #fff;
    }
  }

  &.back {
    background-image: url("./assets/svg/arrow.svg");
    background-repeat: no-repeat;
    background-position: left 15px top 50%;
    background-size: 16px;
    padding: 10px 20px 10px 40px;
  }
}

.photo {
  margin: 0 auto;
  display: block;
  width: 80%;
}
.profile {
  margin: -15px auto;
  display: block;
  width: 350px;
  height: 350px;
  float: left;
  overflow: hidden;
  border-radius: 100%;

  img {
    width: 100%;
  }
}

.ie .btn.next {
  top: 0;
}

.safari .btn.next {
  top: -1px;
}

/*  Columns - Start
---------------------------------------------------------- */
.two-col {
  display: flex;
  flex-wrap: wrap;

  &.pad {
    .col {
      padding-right: 30px;
      &:last-child {
        padding-right: 0;
        padding-left: 30px;
      }
    }
    &.pbottom {
      padding: 0;
    }
  }

  .col {
    width: 50%;
    &.w55 {
      width: 55%;
    }
    &.w45 {
      width: 45%;
    }
  }

  &.stats {
    .col {
      &:nth-child(even) {
        border-right: 0;
      }

      &:nth-child(5),
      &:nth-child(6) {
        border-bottom: 0;
      }
    }
  }
}

.stats {
  text-align: center;
  .col {
    border-right: 1px solid #d1d1d1;
    border-bottom: 1px solid #d1d1d1;
    padding: 20px 10px;
    text-align: center;

    img {
      width: 100%;
      max-width: 275px;
    }
  }
}

.four-col {
  display: flex;
  flex-wrap: wrap;
  .col {
    width: 25%;
    img {
      width: 100%;
    }
  }
}

.three-col {
  overflow: hidden;
  text-align: center;
  display: flex;
  flex-wrap: wrap;

  .col {
    width: 33.333333%;
    text-align: center;
  }

  &.stats {
    margin: 35px 0;
    .col {
      &:nth-child(3),
      &:nth-child(6) {
        border-right: 0;
      }

      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(4) {
        border-bottom: 0;
      }
    }
  }

  &.values {
    padding-top: 20px;
    .col {
      padding: 0 10px;
      display: inline-flex;
      &:last-child {
        padding-right: 0;
        padding-left: 20px;
      }
      &:first-child {
        padding-left: 0;
        padding-right: 20px;
      }

      img {
        display: inline-block;
        margin-bottom: 20px;
      }

      p {
        padding: 0;
      }

      div {
        display: block;
        padding: 40px;
        border: 3px solid $green-dark;
        position: relative;
        transition: all 0.3s linear;

        &:before {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          content: "";
          border: 0 solid #0cd0a7;
          opacity: 0.3;
          transition: all 0.3s linear;
        }

        &:hover {
          &:before {
            border: 15px solid #0cd0a7;
          }
        }
      }
    }
  }

  &.benefits-cols {
    margin: 0 auto;

    .col {
      padding: 0 30px 20px;

      img {
        margin: 0 auto 20px;
        display: block;
      }
    }
  }

  &.audiences {
    max-width: 1000px;
    margin: 0 auto;

    .col {
      position: relative;
      padding: 0 60px 40px;
      width: 50%;

      h3 {
        font-size: 24px;
      }

      img {
        margin: 0 auto 20px;
        display: block;
      }

      .btn {
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 135px;
        transform: translateX(-50%);
      }
    }
  }

  &.steps {
    justify-content: center;
    .col {
      padding: 30px;
      &.therapy {
        img {
          margin-left: 20px;
        }
      }
      &.support {
        p {
          max-width: 290px;
          margin: 0 auto;
        }
      }
      img {
        display: inline-block;
        margin: 0 0 10px;
        height: 75px;
      }
    }
  }
}

.callout {
  font-weight: bold;
  font-style: italic;
  text-align: center;
  font-size: 18px;
  line-height: 1.5;
  padding: 10px 0 0;
  max-width: 790px;
  margin: 0 auto;
}

.col {
  box-sizing: border-box;
}

h2.pt30 {
  padding-top: 40px;
}

.team {
  overflow: visible;
  padding-bottom: 30px;
  margin: 0 -10px;
  .col {
    padding: 20px 10px;
    text-align: left;
    div.member {
      position: relative;
      height: 100%;
      border: 1px solid #e1e1e1;
      .hold {
        overflow: hidden;
        height: 100%;
        position: relative;
      }
    }
    img {
      width: 100%;
      display: block;
    }

    h3 {
      text-align: left;
      font-weight: 700;
      letter-spacing: 0;
      font-size: 20px;
      padding: 15px 15px 20px;

      span {
        font-size: 16px;
        color: $green;
        display: block;
        font-weight: 500;
        padding-top: 5px;
      }
    }
  }
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  margin-bottom: 30px;
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.news {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;

  .article {
    display: block;
    width: 33.333333%;
    box-sizing: border-box;
    padding: 10px;
    margin-bottom: 30px;

    .border {
      position: relative;
      border: 1px solid #e1e1e1;
      height: 100%;

      figure {
        background-color: #e1e1e1;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        display: block;
        position: relative;
        border-bottom: 1px solid #e1e1e1;

        &.full {
          background-size: cover;
        }

        &:before {
          content: "";
          display: block;
          padding-top: 52.45%;
        }

        &:after {
          display: block;
          content: "";
          background: rgba(0, 0, 0, 0.5);
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          transition: all 0.3s ease-in;
        }
      }

      div {
        padding: 15px 15px 10px;

        h3 {
          text-align: left;
          font-weight: 700;
          letter-spacing: 0;
          font-size: 20px;
          padding: 0;
        }
        span.date {
          font-size: 14px;
          color: $green;
          display: block;
          font-weight: 600;
          padding-top: 5px;
        }
      }
    }
    .toggle {
      transition-delay: 0s;
      &:after {
        background-color: $green;
        background-image: url("./assets/svg/arrow.svg");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 15px;
        transform: rotate(180deg);
        transition-delay: 0s;
        transition: all 0.3s ease-in;
      }
    }
    &:hover {
      figure {
        &:after {
          opacity: 1;
        }
      }
      .toggle {
        transition-delay: 0s;
        &:after {
          background-color: $green-dark;
          transition-delay: 0s;
        }
      }
    }
  }
}

.toggle {
  position: absolute;
  top: calc(100% - 15px);
  right: -9px;
  z-index: 10;
  border: 0;
  background: transparent;
  color: $blue;
  font-size: 13px;
  transition: all 0.5s ease-in;
  cursor: pointer;
  padding: 0;
  span {
    display: inline-block;
    text-decoration: underline;
    position: relative;
    top: -12px;
    opacity: 1;
    padding: 0 8px;
    transition: opacity 0.4s ease-in;
    transition-delay: 0.7s;

    &:after {
      position: absolute;
      z-index: -1;
      display: block;
      background: #fff;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  &:after {
    display: inline-block;
    content: "";
    width: 30px;
    height: 30px;
    background: $green url("./assets/svg/icn-plus.svg") no-repeat center center;
    background-size: 30px;
    border-radius: 50%;
    margin: 0;
    transition: all 0.4s ease-in;
    transition-delay: 0.3s;
  }
}

.openMember {
  .bio {
    top: 0;
  }
  .toggle {
    top: -5px;
    span {
      opacity: 0;

      &:after {
        display: none;
      }
    }
    &:after {
      transform: rotate(45deg);
    }
  }
}
.bio {
  position: absolute;
  left: 0;
  height: 100%;
  top: 100%;
  right: 0;
  overflow: hidden;
  background: $blue;
  transition: all 0.5s ease-in;
  div {
    margin: 40px 15px 30px 30px;
    overflow-y: auto;
    height: calc(100% - 70px);
    color: #fff;
    font-size: 14px;
    line-height: 18px;
    box-sizing: border-box;
    padding-right: 20px;
  }
}

.bio div::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.bio div::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 30px;
}

.bio div::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.4);
  border-radius: 30px;
}

.reasons {
  font-size: 20px;
  margin: 0 auto;
  max-width: 1024px;
  text-align: center;

  .col {
    padding: 30px 22px;
    float: none;
    display: inline-block;
    width: 33%;

    p {
      padding: 0;
    }

    span {
      height: 160px;
      max-width: 260px;
      margin: 0 auto 20px;

      img {
        width: 68%;

        &.penny {
          width: 75%;
          max-width: 220px;
        }

        &.travel {
          max-width: 170px;
        }

        &.gyms {
          width: 63%;
        }

        &.pre-existing {
          width: 65%;
        }
        &.sign {
          width: 56%;
        }
      }
    }
  }
}

#getStartedButtonSecondary {
  opacity: 0;
  visibility: hidden;
  transform: translateX(100%);
  transition: ease 0.2s all;
  position: fixed;
  z-index: 1000;
  right: -1px;
  bottom: 10%;
  padding: 20px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1px solid #d1d1d1;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.lockButton {
  #getStartedButtonSecondary {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }
}

/*  Testimonials
---------------------------------------------------------- */
#testimonials {
  background: #0cd0a7; /* Old browsers */
  background: -moz-linear-gradient(
    -45deg,
    #0cd0a7 0%,
    #148d73 97%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    -45deg,
    #0cd0a7 0%,
    #148d73 97%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    135deg,
    #0cd0a7 0%,
    #148d73 97%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  padding: 0;

  .wrap {
    padding: 0 20px;
  }

  &.bhs,
  &.prescriber {
    .profile {
      margin: 15px auto;
    }
  }
}

.slick-dots {
  cursor: pointer;
  bottom: 20px !important;

  li {
    cursor: pointer;
    margin: 0 3px !important;

    button:before {
      font-size: 12px !important;
      transition: linear 0.2s all;
      cursor: pointer;
      color: #fff !important;
    }
  }
}

blockquote {
  overflow: hidden;
  max-width: 920px;
  margin: 0 auto;
  position: relative;
  color: #fff;
  text-align: left;
  font-size: 24px;
  line-height: 30px;
  padding: 40px 40px 60px 80px;
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
  strong {
    &.italic {
      font-style: italic;
    }
  }

  cite {
    font-size: 18px;
    strong {
      font-style: normal;
    }
  }
  em {
    display: block;
    font-size: 13px;
    padding-top: 5px;
    opacity: 0.6;
    line-height: normal;
  }
}

/*
.slider.bhs {
  background:#FFF;  
  padding: 20px;
  margin-left:40px;
  .profile {
    float: none;
    width: 150px;
    height: 150px;
    margin: 0 auto;
  }
  blockquote {
    color:#6C757D;
    float: none; 
    font-size: 18px;
    line-height: 28px;
    padding: 20px 40px 60px;  
    font-style: italic;
    
    cite {
      font-size:13px;
      strong {
        font-size:16px;
      }
    }     
  }
  
.slick-dots {
	bottom:0 !important;
	li {
		button:before{
			color:#0CD0A7 !important;
		}
	}
}  
  
}
*/

/*  National
---------------------------------------------------------- */
#national {
  .wrap {
    overflow: hidden;
  }

  img {
    float: left;
    max-width: 65%;
    margin-top: 20px;
    position: relative;
    z-index: 10;
  }

  p {
    padding-top: 20%;
    font-size: 19px;
    position: relative;

    &:after {
      display: block;
      background: #d0d4d8;
      height: 1px;
      right: 0;
      bottom: 0;
      left: 50%;
      content: "";
      position: absolute;
    }
  }
}

.animate {
  transform: translateX(10%);
  opacity: 0;

  &.fadeBackOut {
    opacity: 0;
    transition: ease-out 0.3s all;
  }

  &.fadeIn {
    transform: translateX(0);
    opacity: 1;
    transition: ease-out 0.3s all;
  }

  &.fadeOut {
    transform: translateX(-10%);
    opacity: 0;
    transition: ease-out 0.3s all;
  }
}

div.p40 {
  padding: 40px 0;
}

/*  Section
---------------------------------------------------------- */
section {
  &.p20 {
    padding: 20px 0;
  }

  &.p30 {
    padding: 30px 0;
  }

  &.pt30 {
    padding: 30px 0 0;
  }

  &.pb30 {
    padding: 0 0 30px;
  }

  &.p40 {
    padding: 40px 0;
  }

  &.p60 {
    padding: 60px 0;
  }

  &.pt60 {
    padding: 60px 0 0 0;
  }
  &.pb60 {
    padding: 0 0 60px 0;
  }

  &.mt60 {
    margin-top: 60px;
  }

  &.p100 {
    padding: 100px 0;
  }

  &.divide {
    border-top: 1px solid #d0d4d8;
  }

  &.grey {
    background: #f4f4f4;
  }

  &.dark {
    background: #0cd0a7; /* Old browsers */
    background: -moz-linear-gradient(
      -30deg,
      #0cd0a7 0%,
      #148d73 97%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      -30deg,
      #0cd0a7 0%,
      #148d73 97%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      115deg,
      #0cd0a7 0%,
      #148d73 97%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

    //background: $green-dark ;
    //    background: transparent linear-gradient(180deg, #5B57DC 0%, #201D88 100%) 0% 0% no-repeat padding-box;
    color: #fff;
    text-align: left;
    margin-bottom: -1px;
  }
}

.newsVideo {
  padding: 10px 30px 30px;
  margin-top: 30px;
  background: rgba(91, 87, 220, 0.15);
  position: relative;
  margin-bottom: 30px;
  border: 1px solid rgba(91, 87, 220, 0.25);

  @media (max-width: 725px) {
    padding: 0 15px 15px;

    h2 {
      font-size: 20px;
    }
  }
}

.fpo {
  display: block;
  margin: -20px auto 0;
  width: 40%;
  padding: 30px;
  border-radius: 100%;
  background: #f7f7f7;
  text-align: center;
  height: 250px;
  width: 250px;
  padding: 70px 0;
  box-sizing: border-box;
  color: #ccc;
  border: 1px solid #e1e1e1;
  img {
    display: block;
    margin: 0 auto;
    padding-bottom: 20px;
  }
}

#doc {
  //background: url('./assets/svg/search1.svg') no-repeat center left, url('./assets/svg/search2.svg') no-repeat center right;
  background-size: auto 250px, auto 250px;
  padding: 80px 0;

  h4 {
    margin: 0 auto;
    max-width: 600px;
  }
}

.btns {
  margin: 0 -5px;
  .btn {
    margin: 0 5px;
  }
}

#cta {
  overflow: hidden;
  h2 {
    text-align: left;
    font-size: 32px;
    font-weight: bold;
    padding-bottom: 10px;
    max-width: 50%;
    color: #fff;
  }
  h3 {
    text-align: left;
    font-size: 24px;
    padding-bottom: 10px;
    max-width: 50%;
    color: #fff;
    font-weight: 300;
    line-height: 36px;

    &.pt30 {
      padding-top: 30px;
    }
  }
  p {
    font-size: 18px;
    line-height: 30px;
    max-width: 50%;
    padding-bottom: 0;
  }

  .btns {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: 15px;

    .btn {
      position: static;
      transform: none;
      width: auto;
      margin: 0 5px;
    }
  }

  .btn {
    border: 1px solid #fff;
    position: absolute;
    top: 50%;
    right: 20px;
    width: 20%;
    text-align: center;
    transform: translateY(-50%);
  }

  img {
    position: absolute;
    bottom: -60px;
    right: 25px;
    width: 440px;
  }
}

.roiImage {
  width: 100%;
  @media (max-width: 500px) {
    width: 90%;
  }
}

.roiHeaderImage {
  width: 100%;
  height: 100%;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0)
    ),
    url("assets/roi-header.jpg") no-repeat center center;
  background-size: cover;
}

.roiFooter {
  background: linear-gradient(
      to left,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    ),
    #f1f1f1 url("assets/roi-footer.png") no-repeat left center;

  background-size: cover;
  padding: 100px 0;
  position: relative;

  @media (max-width: 950px) {
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.5);
      content: "";
    }
  }
}

.teen {
  background: #f1f1f1 url("assets/bg-banner-teen.jpg") no-repeat left center;
  background-size: cover;
  padding: 100px 0;
  position: relative;
  p {
    font-size: 17px;
    line-height: 24px;
    padding-bottom: 30px;
    &.last {
      padding-bottom: 0;
    }
  }
  .btn {
    position: static;
    width: auto;
    margin: 30px 0 0;
    transform: none;
    border: 1px solid #fff;
  }

  @media (max-width: 1040px) {
    background-position: left 25% center;
    background-size: auto 100%;
  }

  @media (max-width: 950px) {
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.5);
      content: "";
    }
  }

  @media (max-width: 825px) {
    background: #f1f1f1;
    padding: 20px 0 !important;
    &:before {
      display: none;
    }
    img {
      display: block;
      max-width: 300px;
      width: 80%;
      margin: 0 auto 40px;
      border-radius: 100%;
    }
  }
}

.actions {
  h3 {
    padding: 30px 0 5px 0;
  }
}

/*  Quiz
---------------------------------------------------------- */
.quiz {
  padding-top: 160px;
  overflow-x: hidden;

  .resultsContainer {
    .wrap {
      padding: 20px;
    }
  }

  .wrap {
    position: relative;
    padding: 20px 90px;
    max-width: 960px;

    &.families {
      padding: 20px;
    }

    &.ratings {
      max-width: 1060px;
    }
  }

  section {
    .ratings p {
      font-size: 15px;
      line-height: 21px;
      padding: 0;
    }

    p {
      font-size: 20px;
      line-height: 25px;
      padding-top: 10px;

      &.essential {
        text-align: center;
        font-size: 22px;
        border-top: 1px solid #d0d4d8;
        max-width: 725px;
        margin: 0px auto;
        padding: 30px 0 0;
        line-height: 30px;
      }

      .cdp {
        padding: 0;
      }

      &.info {
        font-size: 15px;
        line-height: normal;
        &:before {
          top: 8px;
          background-size: 18px;
          margin-right: 5px;
        }

        &.medicare-disclaimer {
          font-size: 17px;
          padding-bottom: 0;
        }
      }
    }
  }
}

.user {
  max-width: 880px;
  margin: 30px auto;
  display: flex;

  li {
    padding: 10px;
    width: 100%;
    text-align: center;
  }

  a {
    display: block;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    color: #fff;
    position: relative;
    font-size: 22px;
    cursor: pointer;

    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }

    &:after {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-repeat: no-repeat;
      background-position: center center;
      background-color: transparent;
      z-index: 1;
      transition: linear 0.2s opacity;
      opacity: 0;
    }

    span {
      position: absolute;
      bottom: 8%;
      left: 0;
      right: 0;
      height: auto;
      margin: 0;
      z-index: 2;
    }

    /*
		&.me {background-image:url('./assets/svg/individual.svg')}
		&.me:after {background-image:url('./assets/svg/individual-over.svg')}

		&.family {background-image:url('./assets/svg/family.svg')}
		&.family:after {background-image:url('./assets/svg/family-over.svg')}

		&.employer {background-image:url('./assets/svg/employer.svg')}
		&.employer:after {background-image:url('./assets/svg/employer-over.svg')}

		&.couple {background-image:url('./assets/svg/couple.svg')}
		&.couple:after {background-image:url('./assets/svg/couple-over.svg')}

		&.withkids {background-image:url('./assets/svg/withkids.svg')}
		&.withkids:after {background-image:url('./assets/svg/withkids-over.svg')}

		&.children {background-image:url('./assets/svg/children.svg')}
		&.children:after {background-image:url('./assets/svg/children-over.svg')}
*/

    &:hover:after {
      opacity: 1;
    }
  }

  &.four {
    li {
      padding: 0;
    }

    a {
      font-size: 14px;
    }
  }
}

#chp {
  position: absolute;
  opacity: 0;

  &:checked + label:before {
    //background-image:url('./assets/svg/label-checked.svg');
    background-size: 100%;
    width: 25px;
    height: 40px;
  }
}

#chp-label {
  position: relative;
  display: inline-block;
  padding: 0 0 0 30px;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
  cursor: pointer;

  &:before {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    //background-image:url('./assets/svg/label-unchecked.svg');
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: 100%;
    position: absolute;
    left: 0;
    bottom: 2px;
  }
}

/*  Form
---------------------------------------------------------- */

.form {
  margin-top: 20px;
  margin-bottom: 60px;

  .required {
    text-align: right;
    font-size: 12px;
    padding: 0;
  }

  label {
    display: block;
    color: #000;
    padding-bottom: 2px;
  }

  input[type="text"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  select,
  textarea {
    background-color: #f5f8fa;
    border: 1px solid #cbd6e2;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 22px;
    border-radius: 3px;
    box-sizing: border-box;
    display: block;
    -webkit-appearance: none;
    appearance: none;
    padding: 8px 12px;
    width: 100%;
    &:focus {
      border-color: $green;
      box-shadow: inset 0px 0px 0px 1px $green;
    }
    &.error {
      border: 1px solid #cc0000;
    }
  }

  select {
    background-image: url("./assets/svg/arrow-down.svg");
    background-repeat: no-repeat;
    background-position: right 10px top 50%;
    background-size: 20px;
    margin-top: 5px;
  }

  p {
    padding-bottom: 25px;
    &.name {
      display: flex;
      flex-wrap: wrap;
      label {
        width: 100%;
      }
      span {
        display: block;
        box-sizing: border-box;
        width: 50%;
        padding-right: 10px;
        &:last-child {
          padding-left: 10px;
          padding-right: 0;
        }
      }
    }
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#age,
#kids,
#household,
#employees {
  width: 80px;
}

#income {
  padding-left: 30px;
}

.income-form {
  position: relative;
  display: inline-block;

  &:before {
    content: "$";
    position: absolute;
    top: 25px;
    left: 15px;
    display: block;
    z-index: 1;
    font-size: 20px;
    opacity: 0.6;
  }
}

.radio {
  display: flex;
  justify-content: center;
  max-width: 780px;
  margin: 30px auto;

  &.two label {
    width: 50%;
  }

  label {
    display: block;
    padding: 30px;
    border: 1px solid #d2d5d7;
    border-radius: 4px;
    margin: 0 15px;
    font-size: 19px;
    line-height: 26px;
    box-sizing: border-box;
    transition: all 0.2s linear;
    cursor: pointer;

    &:hover {
      background-color: rgba(107, 115, 123, 0.1);
    }
  }

  &.budget label {
    padding: 20px 0;
    width: 25%;
    font-size: 20px;
    margin: 0 10px;
  }

  input[type="radio"] {
    opacity: 0;
    position: absolute;
    &:checked + label {
      background: $green;
      border-color: $green;
      color: #fff;
      box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
    }
  }
}

.w60 {
  width: 60%;
}

.nowrap {
  white-space: nowrap;
}

/*  Intro
---------------------------------------------------------- */

#intro {
  text-align: center;
  padding-bottom: 80px;

  &.pt60 {
    padding: 60px 0 0 0;
  }

  &.nopad {
    padding: 0;
  }

  &.welcome {
    padding-bottom: 0;
  }

  h3 {
    font-size: 30px;
    line-height: 40px;
    max-width: 820px;
    margin: 10px auto;
  }

  h4 {
    font-size: 22px;
    line-height: 32px;
  }
}

.wmhd-disc {
  font-size: 12px;
  margin-top: 20px;
  margin-bottom: 60px;
  list-style: decimal;
  padding-left: 18px;
  li {
    line-height: normal;
    padding-bottom: 4px;
    a {
      color: #999;
    }
  }
}

/* Results Table
---------------------------------------------------------- */
span.dropdown {
  background: rgba(0, 0, 0, 0.05);
  padding: 0 2px;
  color: $green;
  display: inline-block;
  margin-top: 5px;
  cursor: pointer;
  transition: linear 0.2s all;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  &:after {
    display: inline-block;
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $green;
    margin: 0 0 0 3px;
    position: relative;
    top: -5px;
  }
}

.tippy-tooltip-content ul.dropdownmenu {
  list-style: none;
  margin: -20px;
  width: 125px;
  padding: 0;
  li {
    padding: 0;
    &:first-child {
      border-bottom: 1px solid #d1d1d1;
    }
    a {
      display: block;
      padding: 10px 30px;
      font-size: 16px;
      background-position: 7px 12px;
      background-repeat: no-repeat;
      background-size: 16px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }
}

.contact {
  font-size: 19px;
  padding-bottom: 60px;
  h5 {
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 20px;
  }
  .visit p {
    padding-top: 20px;
  }
}

.sitemap {
  .logo {
    margin: 50px auto 10px;
  }
  div {
    display: flex;
    justify-content: center;
    ul {
      width: 25%;
      padding: 30px;
      box-sizing: border-box;

      strong {
        border-bottom: 1px solid #e1e1e1;
        display: block;
        margin-bottom: 10px;
        padding-bottom: 5px;
      }
    }
  }
}

/*  Footer
---------------------------------------------------------- */
h6 {
  padding: 20px 0;
  text-align: center;
  color: $blue;
  font-size: 18px;
  line-height: 26px;

  span {
    white-space: nowrap;
  }

  strong {
    color: $green;
  }
}

footer {
  background: $blue;
  color: #fff;
  font-size: 12px;
  line-height: 20px;
  padding: 15px 0;
  img {
    width: 200px;
    display: block;
    margin: 8px 0 15px;
  }
  address {
    font-style: normal;
    text-align: left;
    p {
      padding: 0;
      &.copyright {
        padding-top: 0;
        color: #bababa;
        position: absolute;
        bottom: 20px;
        right: 20px;
      }
      span {
        white-space: nowrap;
      }
    }
  }
  .links {
    float: right;
    padding-top: 15px;
    padding-bottom: 20px;

    ul {
      overflow: hidden;
      padding-bottom: 15px;
      display: flex;
      justify-content: flex-end;
    }

    li {
      padding-left: 12px;
      margin-left: 12px;
      border-left: 1px solid rgba(255, 255, 255, 0.3);
      line-height: normal;

      &:first-child {
        border: 0;
        padding-left: 0;
        margin-left: 0;
      }

      a {
        display: block;
        padding: 0;
        color: $green;
        border-bottom: 1px solid transparent;
        font-size: 15px;

        &:hover {
          color: $green;
          border-bottom: 1px solid $green;
        }
      }
    }
  }
}

/* Loading
---------------------------------------------------------- */
.loading {
  // 	position: relative;
  overflow: hidden;

  &:before {
    position: fixed;
    background: #fff;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: "";
    display: block;
    z-index: 10;
  }
  &:after {
    position: fixed;
    content: "";
    left: 50%;
    top: 50%;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    border: 7px solid rgba(0, 0, 0, 0.2);
    border-top-color: $green;
    animation: spin 1s infinite linear;
    z-index: 20;
    margin: -18px 0 0 -18px;
  }
}

#overlay .loading:before {
  position: absolute;
}

.inlineForm .loading:before,
.inlineForm .loading:after {
  position: absolute;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#success {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  display: none;
  z-index: 9;

  &.show {
    display: block;
  }

  div {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;

    p {
      max-width: 75%;
      margin: 0 auto;
    }
    em {
      font-weight: 600;
    }
  }
}

/* Modals */
.modal-open {
  overflow: hidden;
  height: 100%;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
}

.modal.show {
  #overlayBG {
    visibility: visible;
    opacity: 1;
  }

  #overlay {
    transform: translate(-50%, 0);
    opacity: 1;
    visibility: visible;
  }
}

.startOver {
  #overlay {
    max-width: 650px;
    width: 100%;
    margin-left: -325px;
    top: 120px;

    .wrap {
      padding: 60px 20px;
    }
    .btn {
      margin: 5px;
      width: 175px;
      padding: 15px 0;
    }
  }
}

#overlayBG {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  overflow-y: auto;
  right: 0;
  z-index: 10010;
  transition: all 0.3s linear;
  opacity: 0;
  box-sizing: border-box;
  visibility: hidden;
  -webkit-overflow-scrolling: touch !important;
  overflow: auto !important;
  height: 100% !important;
  width: 100%;

  .close {
    position: fixed;
    top: 10px;
    right: 10px;
    border: 0;
    padding: 0;
    margin: 0;
    text-indent: -99999em;
    width: 30px;
    height: 30px;
    z-index: 10;
    opacity: 0.6;
    transition: opacity 0.2s linear;
    cursor: pointer;
    background: transparent url("./assets/close.png") no-repeat center center;
    background-size: 13px;

    &:hover {
      opacity: 1;
    }
  }
}

#overlay {
  position: absolute;
  z-index: 11000;
  font-size: 18px;
  left: 50%;
  max-width: 650px;
  width: 100%;
  box-sizing: border-box;
  top: 60px;
  padding: 10px;
  margin-bottom: 20px;
  opacity: 0;
  transform: translate(-50%, -20px);
  visibility: hidden;
  transition: opacity 0.3s linear, visibility 0.3s linear, transform 0.3s linear;

  &.meeting {
    max-width: 960px;
    h3 {
      margin: 0;
      padding-bottom: 10px;
    }
  }

  h3.noMargin {
    margin: 0;
  }
  .wrap {
    background: #fff;
    padding: 50px 30px 20px 40px;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
    position: relative;
  }
}

.hs-form-iframe {
  width: 100% !important;
}

.ie .inlineForm form {
  max-width: 475px;
}

#overlay,
.inlineForm {
  &.inlineForm {
    .wrap {
      background: transparent;
    }
  }

  h3 {
    margin-bottom: 30px;
  }

  h4 {
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 10px;

    &.medicare {
      line-height: normal;
      margin-bottom: 20px;
    }
  }

  form {
    text-align: left;
    max-width: 450px;
    margin: 0 auto;
    padding: 20px 0 30px;
    font-size: 20px;
    line-height: normal;

    em.desc {
      font-size: 14px;
      color: #6b737b;
      display: inline-block;
      margin-left: 20px;
      line-height: normal;
      position: relative;
      top: 6px;
    }

    .submitForm {
      padding-top: 15px;
    }

    .pardot_extra_field {
      position: absolute;
      left: -9999px;
      top: -9999px;
    }
  }

  .county {
    span {
      display: inline-block;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 10px solid #d1d1d1;
      }

      select {
        -webkit-appearance: none;
        background: none;
        width: 340px;
        z-index: 5;
        position: relative;
      }
    }
  }

  input[type="text"],
  input[type="tel"],
  input[type="email"],
  select {
    border: 0;
    border-bottom: 1px solid #000;
    font-size: 20px;
    margin-left: 5px;
    font-family: "Source Sans Pro", sans-serif;
    display: inline-block;
    border-radius: 0;
    box-sizing: border-box;

    &.first_name,
    &.last_name {
      width: 170px;
    }

    &.phone {
      width: 274px;
    }

    &.company {
      width: 320px;
    }

    &.email {
      width: 282px;
    }

    &.error {
      border-bottom: 2px solid #fba945;
      color: #fba945;

      &::-webkit-input-placeholder {
        color: #fba945;
      }
      &::-moz-placeholder {
        color: #fba945;
      }
      &:-ms-input-placeholder {
        color: #fba945;
      }
      &:-moz-placeholder {
        color: #fba945;
      }
    }
  }

  .callUs {
    position: absolute;
    bottom: 0;
    border-top: 1px solid #d0d4d8;
    left: 0;
    right: 0;
    text-align: center;
    padding: 15px 0;
    font-size: 16px;
  }

  .guide {
    .guide-icon {
      float: left;
      width: 225px;
      &.medicare-icon {
        width: 205px;
      }
    }

    form {
      margin: 0 0 0 250px;
      max-width: none;
      padding-bottom: 0;
      h4 {
        text-align: left;
        font-size: 25px;
      }
    }
  }
}

.medicareForm {
  #overlay,
  .inlineForm {
    .wrap {
      max-width: 860px;
    }

    .form-content {
      overflow: hidden;
      padding: 20px 20px 20px;

      h3 {
        font-size: 22px;
        font-weight: 700;
        text-align: left;
        margin-bottom: 20px;
      }

      form {
        float: left;
        width: 55%;
        padding-top: 25px;

        em.desc {
          top: 0;
          margin-left: 10px;
        }
      }

      aside {
        background: #f6f6f3;
        border: 1px solid #d1d1d1;
        border-radius: 4px;
        padding: 25px;
        margin-left: 58%;
        text-align: left;

        h3 {
          font-size: 22px;
          font-weight: 700;
          text-align: left;
          margin-bottom: 20px;
        }

        h4 {
          font-size: 18px;
          text-align: left;
          margin: 0;
        }

        ul {
          border-top: 1px solid #d1d1d1;
          list-style: disc;
          padding: 15px 0 15px 20px;
          font-size: 16px;
        }

        p {
          border-top: 1px solid #d1d1d1;
          padding: 10px 0 0;
          font-size: 18px;
        }
      }
    }
  }
}

.careers {
  margin: 30px 0;
  h4 {
    text-align: left;
    margin-bottom: 20px;
  }
}

.jobs {
  font-size: 16px;
  line-height: 22px;

  .whr-group {
    font-size: 22px;
    margin: 0;
    padding: 0 0 25px;
    line-height: normal;
    &:after {
      content: "";
      display: block;
      background: #e0e0e0;
      height: 2px;
      width: 100px;
      margin-top: 10px;
    }
  }
  .whr-items {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px 80px;

    .whr-item {
      width: 33.33333%;
      padding: 20px;
      box-sizing: border-box;
    }
  }

  h2 {
    padding-bottom: 50px;
  }
  h3 {
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 5px;
  }

  ul {
    li {
      padding: 0 0;
      font-size: 14px;

      em {
        font-size: 13px;
      }

      span {
        font-weight: bold;
      }
    }
  }
}

/*  HubSpot Override
----------------------------------------------------------  */
.hbspt-form .hubspot-link__container.sproket {
  display: none !important;
}

/*  Light Up Campaign
----------------------------------------------------------  */
#lightUp {
  .wrap {
    max-width: 1300px;
  }
  .vid {
    margin-top: 0;
  }

  #banner {
    padding-top: 78px;
    height: 460px;
    background: #002238;

    &.prescribers {
      padding-bottom: 0;
      height: 440px;
      &:after {
        display: none;
      }
      .wrap {
        background: #002238 url("./assets/bg-prescriber-hero-01.gif") no-repeat
          center center;
        background-size: auto 100%;
      }
    }

    .wrap {
      padding: 0;
      box-sizing: border-box;
      height: 100%;
    }

    h1 {
      text-align: center;
      position: absolute;
      top: 50%;
      width: 100%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
      font-family: parisplus-clair, sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 75px;
      line-height: 85px;
      max-width: 420px;
      text-align: center;
      letter-spacing: 0px;
      color: #0cd0a7;
      text-shadow: 0px 0px 10px #91ffe8;
    }
    .slider,
    .slick-slider,
    .slick-slide,
    .slick-list,
    .slick-track {
      height: 100% !important;
    }

    .slick-slide {
      div {
        height: 100%;
      }
    }

    .slider {
      .slide {
        background-color: #002238;
        background-position: top center;
        background-size: auto 100%;
        height: 100%;
        &.campaign01 {
          background: transparent url("./assets/bg-campaign-hero-01.gif")
            no-repeat center center;
          background-size: auto 100%;
        }
        &.campaign02 {
          background: transparent url("./assets/bg-campaign-hero-02.gif")
            no-repeat center center;
          background-size: auto 100%;
        }
        &.campaign03 {
          background: transparent url("./assets/bg-campaign-hero-03.gif")
            no-repeat center center;
          background-size: auto 100%;
        }
      }
    }
  }

  .intro {
    background: #fff;
    h3 {
      font-size: 38px;
      padding-bottom: 25px;
    }

    p {
      font-size: 18px;
      line-height: 26px;
    }

    .btn {
      font-weight: bold;
      background: #5b57dc;

      &:hover {
        background: #1a1776;
      }
    }
  }

  #accordion {
    position: relative;
    .wrap {
      min-height: 550px;
    }

    &:before {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background: linear-gradient(
        180deg,
        rgba(244, 244, 244, 1) 0%,
        rgba(244, 244, 244, 0) 68%
      );
      height: 50%;
      content: "";
    }

    h2 {
      font-size: 38px;
      text-align: left;
      padding-bottom: 20px;
      margin: 0;
    }

    .item {
      h4 {
        &:after {
          display: inline-block;
          height: 20px;
          width: 20px;
          background: transparent url("./assets/svg/arrow-right.svg") no-repeat
            center center;
          content: "";
          margin-left: 3px;
          position: relative;
          top: 2px;
          transition: ease 0.2s all;
        }
        &:hover {
          &:after {
            margin-left: 8px;
          }
        }
      }

      &.active {
        h4 {
          &:after {
            transform: rotate(-90deg);
            margin-left: 3px;
            opacity: 0.2;
          }
        }
      }
    }
  }

  .benefits {
    h2 {
      text-align: center;
      font-size: 38px;
      padding-bottom: 60px;
    }

    h4 {
      color: $green;
      font-weight: bold;
      padding: 10px 0 20px 0px;
      font-size: 24px;
      line-height: 26px;
      max-width: 280px;
      margin: 0 auto;
    }

    img {
      height: 100px;
      display: block;
    }

    .three-col {
      p {
        text-align: left;
        padding: 0 0 10px 0;
      }

      ul {
        list-style: disc;
        color: $green;
        padding: 0 20px;

        li {
          text-align: left;
          padding-bottom: 10px;

          span {
            color: #5f5f5f;
          }
        }
      }
    }
  }

  #testimonials {
    blockquote {
      p {
        line-height: 34px;
      }
    }
  }

  .bhs-stats {
    padding-top: 30px;
    h3 {
      color: $green;
      sup {
        font-size: 15px;
        top: -10px;
      }
    }

    img {
      max-width: 450px;
      width: 80%;
      margin-bottom: 20px;
    }
  }

  .ref {
    margin-top: 30px;
  }
}

.pcta {
  text-align: center;
  padding: 60px 0 20px;
}

.disclaimer {
  font-size: 15px;
  max-width: 520px;
  line-height: 1.5;
  margin: 0 auto;
  text-align: center;
  &.left {
    text-align: left;
    margin: 20px 0 0;
    max-width: none;
  }
}

#signup {
  text-align: left;
  background: #f1f1f1 !important;
  padding-bottom: 40px !important;

  h3 {
    color: $green;
    margin: 0;
    padding: 0;
  }

  p {
    padding-top: 10px;
    font-size: 16px;
  }

  label {
    display: none;
  }

  input[type="text"],
  input[type="email"],
  select {
    height: auto;
    border: 1px solid #d0d4d8;
    padding: 10px 15px;
    border-radius: 4px;
    box-sizing: border-box;
    display: block;
    font-size: 16px;
    line-height: normal;
    position: relative;
    width: 100%;
    transition: all 0.2s linear;
    -webkit-appearance: none;
    background: #fff;
    margin: 0 0 15px;

    &:focus {
      border-color: $green;
      box-shadow: inset 0px 0px 0px 1px $green;
    }
  }

  select {
    background: #fff url("./assets/arrow-down.svg") no-repeat right 10px top 50%;
    background-size: 20px;
  }

  .btn {
    margin-top: 10px;
  }

  .success {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #fff;
    z-index: 8;
    div {
      position: absolute;
      width: 100%;
      top: 50%;
      left: 0;
      right: 0;
      padding: 40px;
      box-sizing: border-box;
      transform: translateY(-50%);
    }
    h4 {
      font-size: 22px;
      line-height: normal;
      font-weight: normal;
      color: $green;
    }
    p {
      text-align: center;
      font-size: 18px;
      a {
        color: #000;
        text-decoration: underline;
        font-weight: bold;
      }
    }
  }
}

/*  Video Landing Pages
---------------------------------------------------------- */
.videoContent {
  padding-top: 90px;
  padding-bottom: 20px;
  background: #f4f4f4;

  h2 {
    font-size: 32px;
    padding-top: 10px;
  }

  .app {
    display: block;
    background: #222;
    border-radius: 10px;
    img {
      transition: ease 0.2s all;
    }
    &:hover {
      img {
        opacity: 0.8;
      }
    }
  }

  .description {
    padding-top: 40px;
    max-width: 900px;
    margin: 0 auto;
    font-size: 18px;
    line-height: 30px;

    h3 {
      padding-top: 30px;
      &:first-child {
        padding-top: 0;
      }
    }

    .four-col {
      margin: 0 -20px;
      .col {
        width: 22%;
        font-weight: 600;
        padding: 25px;
        line-height: 22px;
        font-size: 16px;
        text-align: center;

        &:last-child {
          width: 32%;
        }

        img {
          width: auto;
          height: 75px;
          margin-bottom: 20px;
          display: inline-block;
        }
      }

      @media (max-width: 725px) {
        .col {
          width: 50% !important;
          padding: 25px;
          text-align: left;
          p {
            padding: 0;
            margin-left: 70px;
          }

          img {
            float: left;
            height: auto;
            width: 50px;
          }
        }
      }

      @media (max-width: 525px) {
        margin: 0;
        .col {
          width: 100% !important;
          padding: 0 0 40px;
          overflow: hidden;
          &.therapy {
            img {
              margin-left: 10px;
            }
          }
          p {
            padding: 0;
            margin-left: 90px;
          }

          img {
            height: 60px;
            width: auto;
            margin: 0;
          }
          &:last-child {
            img {
              height: 55px;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }

  .download {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    border-top: 1px solid #e1e1e1;

    p {
      padding: 0;
      text-align: center;
    }

    .or {
      display: block;
      padding: 0 30px;
      font-size: 18px;
      color: #08202c;
    }
    img {
      max-width: 150px;
      width: 100%;
      display: block;
    }
  }

  @media (max-width: 825px) {
    padding-top: 60px;

    h2 {
      font-size: 26px;
    }

    .download {
      .or {
        padding: 0 20px;
        font-size: 16px;
      }
    }
  }

  @media (max-width: 525px) {
    h2 {
      font-size: 24px;
      padding: 0 0 20px;
      line-height: 26px;
    }

    .description {
      padding-top: 30px;
      font-size: 15px;
      line-height: 22px;
      h3 {
        padding-bottom: 10px;
      }
      .four-col {
        padding-top: 20px;
      }
    }

    .download {
      padding-top: 30px;
      flex-wrap: wrap;
      p {
        width: 100%;
        padding: 0 0 10px 0;
        img {
          display: inline-block;
          max-width: 175px;
        }
        &:last-child {
          padding: 0;
        }
      }
    }
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/* PCP Landing Page
---------------------------------------------------------- */
#calc {
  // background:#133C51;
  padding: 0 10px;
  .wrap {
    background: #133c51;
    padding-top: 60px;
    padding-bottom: 60px;
    border-radius: 5px;

    display: flex;
    justify-content: space-evenly;
    color: #fff;
    text-align: center;
    align-items: center;

    div {
      h5 {
        color: #0cd0a7;
        font-size: 26px;
        padding-bottom: 5px;
        font-family: proxima-nova, sans-serif;
      }
    }

    .param {
      font-size: 50px;
      color: rgba(255, 255, 255, 0.4);
      display: block;
      line-height: normal;
      position: relative;
      top: -10px;
    }
  }
}

.pcp-benefits {
  h3 {
    text-align: center;
    color: #0e3c51;
    font-weight: 600;
    font-size: 28px;
  }
  .icons {
    display: flex;
    .icon {
      padding-right: 20px;
      width: 50%;
      &:last-child {
        padding-right: 0;
        padding-left: 20px;
      }
    }
  }
  .icon {
    padding: 30px 0 20px;
    box-sizing: border-box;
    max-width: 360px;
    margin: 0 auto;

    img {
      float: left;
      width: 55px;
    }

    p {
      display: block;
      margin-left: 75px;
      max-width: 300px;
      line-height: 26px;
      padding: 0;
      font-size: 18px;
    }

    strong,
    span {
      display: block;
    }

    strong {
      color: #2d2e2f;
      font-size: 40px;
      font-weight: 700;
      line-height: normal;
      padding: 0 0 5px;
      letter-spacing: -1px;
      font-family: proxima-nova, sans-serif;
      font-style: normal;

      em {
        font-weight: 800;
        font-style: normal;
      }
    }

    span {
      em {
        font-style: normal;
        white-space: nowrap;
      }
    }
  }
}

#cta.pcp {
  img {
    position: static;
    width: 120px;
    float: left;
  }
  .btn {
    position: static;
    transform: none;
    width: auto;
  }
  .wrap {
    div {
      margin-left: 160px;
    }
  }

  h3,
  p {
    max-width: none;
    padding: 0 0 25px 0;
  }

  h3 {
    font-size: 34px;
    line-height: normal;
    padding-bottom: 15px;
  }

  p:last-child {
    padding: 0;
  }

  &.hcp {
    img {
      width: 185px;
      position: absolute;
      top: 50%;
      left: 20px;
      bottom: auto;
      right: auto;
      transform: translateY(-50%);
    }
    .wrap {
      div {
        margin-left: 250px;
      }
    }
  }
}

#cta.lg {
  h3 {
    max-width: 58%;
  }
}

#laptop.pcp {
  position: relative;
  margin-left: -110px;
  right: auto;
  width: calc(65% + 110px);
  bottom: -42px;
  img {
    width: 100%;
  }
}

#accordion.pcp {
  background: url("./assets/bg-accordion-pcp.jpg") repeat-x bottom center;
  overflow: visible;
  margin-bottom: 60px;
  padding-bottom: 0;
  h2 {
    padding-bottom: 10px;
  }
  .wrap {
    padding-bottom: 0;
  }
  .container {
    overflow: visible;
    ul.checks {
      width: 35%;
    }
  }
}

.standardized {
  h2 {
    text-align: center;
    font-size: 36px;
  }
  .headline {
    max-width: 960px;
  }

  .two-col {
    padding: 0 0 40px;
    max-width: 960px;
    margin: 0 auto;
    .col {
      text-align: center;
      img {
        height: 100px;
        display: inline-block;
        margin: 0 0 20px;
      }
    }
  }
  /*
  .col {
    overflow: hidden;
    img {
      float: left;
      max-width: 100px;
    }
    
    
    h3, p {
      margin-left:130px;
    }
  }
*/

  .callout {
    padding: 0;
    p:first-child {
      padding-bottom: 30px;
    }
  }
}

/*  HCP
---------------------------------------------------------- */
.hcp-steps {
  padding-top: 30px;
  .col {
    img {
      display: block;
      height: 95px;
      margin: 0 auto 20px;
    }
    strong {
      max-width: 220px;
      margin: 0 auto;
      display: block;
      color: $blue;
      font-size: 20px;

      @media (max-width: 725px) {
        font-size: 16px;
        line-height: 20px;
        max-width: 180px;
      }
    }
  }
  @media (max-width: 650px) {
    padding-top: 0;
    .col {
      img {
        height: 60px;
      }
      strong {
        margin-bottom: 30px;
      }
    }
  }
}

.hcp {
  .desc {
    font-size: 14px;
    font-style: italic;
    line-height: normal;
    max-width: 900px;
  }
}

/*  Event
---------------------------------------------------------- */
.event {
  margin-top: 76px;
  background: #7270d5;
  color: rgba(255, 255, 255, 0.75);
  position: relative;

  &.blue {
    background: #0e3c51;
    padding: 20px 0;

    .details {
      border-top: 1px solid rgba(255, 255, 255, 0.3);
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      margin: 0 0 20px;
      padding-bottom: 0;
      max-width: 520px;
    }
    .btn {
      max-width: 520px;
      width: 100%;
    }
  }

  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 0.3) 100%
    );
  }

  strong {
    color: #fff;
  }

  h1 {
    padding: 40px 0 20px;
    font-size: 40px;
    font-weight: 700;
    padding-bottom: 20px;
    line-height: 45px;
    color: #fff;
  }
  h5 {
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    padding-bottom: 20px;
  }

  .two-col {
    .col {
      &.w60 {
        width: 60%;
        .btn {
          width: 100%;
          max-width: 450px;
          text-align: center;
          box-sizing: border-box;
          padding: 15px 0;
          border: 2px solid #fff;
          font-size: 20px;

          &:hover {
            background: rgba(0, 0, 0, 0.2);
            border: 2px solid #fff;
          }
        }
      }
      &.w40 {
        width: 40%;
        font-size: 16px;
        line-height: 22px;

        strong {
          display: block;
          font-size: 20px;
        }

        em {
          font-size: 14px;
        }

        img {
          padding-top: 30px;
        }
      }
    }
  }

  .moderating {
    border-radius: 100%;
    max-width: 260px;
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto 10px;
  }

  .details {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0 20px;
    margin: 0 -20px;

    div {
      padding: 20px;
    }

    img {
      width: 32px;
      display: block;
      float: left;
    }

    p {
      display: block;
      margin-left: 45px;
      padding: 0;
      white-space: nowrap;
    }
    em {
      display: block;
      font-size: 16px;
      text-transform: uppercase;
      font-style: normal;
      line-height: 20px;
      padding-bottom: 4px;
    }
    strong {
      display: block;
      font-size: 18px;
      line-height: normal;
    }
  }
}

.aptihour {
  /*
  width: 300px;
  height: 300px;
  background:rgba(0,0,0,.2);
  border-radius: 100%;
  display: block;
  position: relative;
  margin: 30px auto 0; 
  border:1px solid rgba(255,255,255,.15);
*/
  img {
    display: block;
    width: 300px;
    margin: 35px 0 20px;
  }
}

.two-col {
  &.divide {
    padding-bottom: 30px;
    border-bottom: 1px solid #d0d4d8;
  }
}

.panelists {
  h2 {
    font-size: 30px;
    line-height: 40px;
    padding: 0;
  }
}

/*  Alert
---------------------------------------------------------- */
.show-alert {
  padding-top: 84px;

  #banner.client {
    padding-top: 120px;
  }

  #alert {
    background: #0cd0a7; /* Old browsers */
    background: -moz-linear-gradient(
      -45deg,
      #0cd0a7 0%,
      #148d73 97%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      -45deg,
      #0cd0a7 0%,
      #148d73 97%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      135deg,
      #0cd0a7 0%,
      #148d73 97%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    color: rgba(255, 255, 255, 0.95);
    font-size: 16px;
    p {
      padding: 0;
    }
    a {
      font-weight: bold;
      color: #00ffca;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      &:hover {
        color: #fff;
        border-bottom: 1px solid rgba(255, 255, 255, 1);
      }
    }
  }

  @media (max-width: 960px) {
    padding-top: 56px;

    #banner.client {
      padding-top: 70px;
    }

    #alert {
      font-size: 14px;
      line-height: 1.3;
    }
  }

  @media (max-width: 550px) {
    #banner.client {
      padding-top: 0;
      margin-top: 0;
    }
    #alert {
      font-size: 13px;
      line-height: 1.5;
      .wrap {
        padding: 15px 20px;
      }
    }
  }
}

/*  Cookie Consent
---------------------------------------------------------- */
.cookieConsent {
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 30px;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  background: #e6faf6;
  width: 312px;
  font-size: 16px;
  border: 1px solid #b6f1e4;
  z-index: 1000;
  box-sizing: border-box;

  @media (max-width: 825px) {
    margin: 0;
    width: 100%;
    border-radius: 0;
  }

  div {
    color: #000;
  }
  .btn {
    display: block;
    margin-top: 10px;
    width: 100%;
    padding: 12px 20px;
    font-size: 16px;
  }
}

.clientPages {
  .checks {
    padding-bottom: 20px;
  }

  blockquote {
    color: $blue;
    border-left: 4px solid #e1e1e1;
    padding: 10px 20px;
    margin: 20px 0 50px;
  }
}

.demoPhoto {
  width: 100%;
  margin: -60px 0 0;
}

/*  Albany Med Team
---------------------------------------------------------- */
#banner.partner-banner {
  // background:#F0E9DC url('assets/bg-banner-amc.png') no-repeat top center;
  // background-size:1220px;
  background: #f0e9dc;
  text-align: left;
  padding: 25px 0;
  margin-top: 77px;
  color: #812705;
  height: auto;
  overflow: hidden;

  .wrap {
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: -25px;
      right: 0;
      bottom: -25px;
      width: 490px;
      background: #ff7470;
    }
  }

  h2 {
    font-size: 32px;
    line-height: 36px;
    color: #812705;
    letter-spacing: 0.52px;
    padding: 0;
    font-weight: normal;
    position: relative;
    text-transform: uppercase;
    strong {
      display: block;
      font-weight: bold;
    }
  }

  h1 {
    font-family: "Barlow", sans-serif;
    font-size: 193px;
    line-height: 200px;
    font-weight: 700;
    padding: 0 0 10px;
    color: #ff7470;
    letter-spacing: -4px;
    position: relative;
    span {
      background: none;
      color: #812705;
      margin: 0;
      padding: 0;
      border-radius: 0;
    }
    strong {
      color: #f0e9dc;
      position: absolute;
      top: 100px;
      right: -26px;
      font-size: 355px;
      letter-spacing: -28px;
    }
  }

  p {
    font-size: 22px;
    max-width: 504px;
    margin-top: 8px;
    line-height: 28px;
    padding-bottom: 30px;
  }

  .btn {
    font-size: 24px;
    border-radius: 40px;
    border: 2px solid #fff;
  }

  .partnerLogos {
    position: absolute;
    right: 35px;
    display: flex;
    align-items: center;
    top: 25px;
    img {
      margin: 0 30px;
      height: 36px;
      &.cdphp {
        height: 48px;
      }
      &.toga {
        height: 90px;
      }
      &.rpi {
        height: 55px;
      }
    }
    &.saratogahospitalteam {
      img {
        &.cdphp {
          height: 50px;
        }
      }
    }
    &.albanymedteam {
      top: 5px;
      right: 55px;
      img {
        &.amc {
          height: 85px;
        }
      }
    }
  }

  @media (max-width: 1220px) {
    h1 {
      font-size: 180px;
      line-height: 185px;
    }
  }

  @media (max-width: 1170px) {
    .wrap {
      &:before {
        width: 425px;
      }
    }

    h1 {
      font-size: 170px;
      line-height: 185px;
      strong {
        top: 90px;
        font-size: 300px;
        letter-spacing: -22px;
      }
    }

    .partnerLogos {
      right: 15px;
      &.albanymedteam {
        right: 20px;
      }
      img {
        margin: 0 25px;
        height: 30px;
        &.cdphp {
          height: 42px;
        }
      }
    }
  }

  @media (max-width: 1070px) {
    .wrap {
      &:before {
        width: 365px;
      }
    }

    h1 {
      font-size: 160px;
      line-height: 175px;
      strong {
        top: 75px;
        font-size: 265px;
      }
    }

    .partnerLogos {
      right: 0;
      &.albanymedteam {
        right: 0;
        top: 0;
        img {
          &.amc {
            height: 60px;
          }
        }
      }
      img {
        margin: 0 20px;
        &.toga {
          height: 80px;
        }
        &.rpi {
          height: 40px;
        }
      }
      &.saratogahospitalteam {
        img {
          &.cdphp {
            height: 38px;
          }
        }
      }
    }
  }

  @media (max-width: 965px) {
    h1 {
      font-size: 140px;
      line-height: 160px;
    }
    h2 {
      font-size: 28px;
    }
    p {
      font-size: 18px;
      max-width: 410px;
    }
  }

  @media (max-width: 890px) {
    .wrap {
      &:before {
        width: 100%;
        bottom: auto;
        height: 240px;
      }
    }

    h1 {
      position: relative;
      color: #f0e9dc;

      strong {
        top: 10px;
        font-size: 200px;
        letter-spacing: -15px;
      }
    }
  }

  @media (max-width: 825px) {
    margin-top: 57px;
    padding-top: 10px;

    .wrap {
      &:before {
        height: 410px;
      }
    }

    &.saratogahospitalteam {
      .wrap {
        &:before {
          height: 445px;
        }
      }
    }

    .partnerLogos {
      z-index: 10;
      position: relative;
      top: auto;
      right: auto;
      justify-content: center;
      padding-bottom: 10px;
      img {
        &.toga {
          height: 65px;
        }
        &.amc {
          height: 85px;
        }
      }
    }

    h2 {
      text-align: center;
      font-size: 25px;
      line-height: 28px;
      margin-top: 10px;
    }
    h1 {
      text-align: center;
      font-size: 105px;
      line-height: 115px;
      strong {
        position: static;
        display: block;
        font-size: 250px;
        line-height: 150px;
      }
    }

    p {
      max-width: 365px;
      margin: 50px auto 0;
    }
    .btn {
      max-width: 380px;
      margin: 0 auto;
      display: block;
      padding: 15px 0px;
      text-align: center;
    }
  }

  @media (max-width: 450px) {
    padding: 0;
    h2 {
      font-size: 22px;
      strong {
        display: inline;
      }
    }
    h1 {
      font-size: 92px;
      strong {
        font-size: 252px;
        line-height: 135px;
        text-indent: -40px;
      }
    }
  }
}

/*  Media Queries
---------------------------------------------------------- */

@media (max-width: 1150px) {
  header {
    height: 78px;
    nav li {
      margin-left: 15px;
    }
  }

  .intro .vid,
  .intro img {
    margin-top: 0;
  }

  #laptop {
    right: -210px;
  }
}

@media (max-width: 1100px) {
  .logo {
    span {
      padding-top: 5px;
      width: 150px;
    }
    strong {
      margin-left: 165px;
      font-size: 11px;
      padding-top: 4px;
    }
  }
  .scroll header .logo {
    width: 170px;
    span {
      background-size: 150px;
      background-position: left 5px;
    }
  }
}

@media (max-width: 1050px) {
  body {
    font-size: 16px;
    line-height: 22px;
  }

  // Client Page
  header.client {
    .logo {
      span {
        padding: 0;
        width: 220px;
      }
      strong {
        font-size: 13px;
        padding-top: 3px;
        margin-left: 235px;
      }
    }
  }

  .three-col.audiences .col img {
    margin: 0 auto 20px;
    display: block;
    height: 90px;
  }

  h2 {
    font-size: 32px;
    line-height: 32px;
  }

  .container .content {
    width: 100%;
  }

  .profile {
    margin: 10px auto;
    display: block;
    width: 280px;
    height: 280px;
  }

  blockquote {
    font-size: 21px;
    line-height: 26px;
    padding: 20px 30px 60px 50px;
  }

  #banner.secondary {
    &.pcp {
      padding: 140px 0 60px;
      h1 {
        width: 50% !important;
        font-size: 30px;
        line-height: normal;
      }
    }
    h1 {
      width: 100% !important;
      font-size: 34px;
      line-height: 36px;
    }
  }

  #cta {
    .btns {
      margin: 0;
      .btn {
        display: block;
        margin: 10px 0;
      }
    }
  }
  .btns {
    margin: 0 0 20px;
    .btn {
      margin: 5px 5px;
    }
  }

  // PCP
  .pcp-benefits {
    h3 {
      font-size: 22px;
    }

    .icon {
      padding: 10px 0 10px;
      img {
        width: 40px;
      }
      p {
        margin-left: 60px;
        line-height: 20px;
        font-size: 16px;
      }
      strong {
        font-size: 28px;
      }
    }
  }

  #calc {
    .wrap div h5 {
      font-size: 22px;
    }
  }

  .intro.pcp {
    .copy {
      font-size: 16px;
      line-height: 22px;
      .btn {
        margin-top: 10px;
      }
    }
  }

  #accordion.pcp {
    margin-bottom: 20px;

    .container ul.checks {
      li {
        background-size: 16px;
        font-size: 14px;
        padding: 0 0 15px 25px;
        background-position: top 3px left 0;
      }
    }
  }

  #accordion.pcp,
  .standardized {
    h2 {
      font-size: 28px;
      line-height: 28px;
    }
    .headline {
      max-width: 800px;
      font-size: 18px;
      line-height: 28px;
    }
    h3 {
      font-size: 22px;
    }
  }
}

@media (max-width: 1000px) {
  .logo {
    strong {
      display: none;
    }
  }
  header.client {
    .logo {
      strong {
        display: block;
      }
    }
  }
}

@media (max-width: 960px) {
  // .logo {
  //   strong {
  //     display: none;
  //   }
  // }
  // header.client {
  //   .logo {
  //     strong {
  //       display: block;
  //     }
  //   }
  // }

  #banner {
    padding-top: 110px;
    height: 410px;

    h1 {
      font-size: 44px;
      line-height: 46px;
    }
  }

  .primary-stat,
  .individual-stat {
    width: 80%;
    padding: 0 0 0 10px;
    margin: 0 auto;
    max-width: 350px;
    display: block;
  }

  #accordion.laptop {
    padding-bottom: 0;

    .wrap {
      padding-bottom: 30px;
    }
    .accordion {
      padding-top: 20px;
      width: 100%;
      float: none;
    }
    #laptop {
      position: static;
      margin: 50px auto 0;
      max-width: 700px;
      .device {
        height: 390px;
      }
    }
  }

  .team .col {
    .bio div {
      margin: 30px 5px 20px 15px;
      overflow-y: auto;
      height: calc(100% - 50px);
    }
    h3 {
      font-size: 18px;
      span {
        font-size: 13px;
      }
    }
  }

  .three-col.audiences .col {
    padding: 0 10px 40px;
  }

  // Client Pages
  .headline {
    font-size: 18px;
    line-height: 28px;
  }

  .clientIntro .copy h4 {
    font-size: 20px;
    line-height: 30px;
  }

  .clientPages {
    .two-col {
      h3 {
        font-size: 22px;
      }
    }
  }

  section.pt60 {
    padding-top: 30px;
  }
  .demoPhoto {
    margin: 0;
  }
}

.mobile,
#menu {
  display: none;
}

.desktop {
  display: block;
}

@media (max-width: 875px) {
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }

  #menu {
    position: absolute;
    top: 0;
    padding: 26px 15px 25px;
    left: 0;
    display: block;
    z-index: 800;
    appearance: none;
    background: transparent;
    border: 0;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
  }

  #menu span,
  #menu span:after,
  #menu span:before {
    display: block;
    width: 30px;
    height: 3px;
    background: $green;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }

  #menu span:after,
  #menu span:before {
    content: "";
    position: absolute;
  }

  #menu span:before {
    margin-top: -9px;
  }

  #menu span:after {
    margin-top: 9px;
    width: 20px;
  }

  .openMenu #menu span {
    background: transparent;
  }

  .openMenu #menu span:after {
    margin: 0;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    width: 30px;
  }
  .openMenu #menu span:before {
    margin: 0;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .openMenu nav {
    height: 100%;
    padding: 0 0 0 10px;
  }

  #banner {
    padding: 100px 0 20px;
    height: auto;
    h1 {
      font-size: 34px;
      line-height: 38px;
      padding-top: 0;
      br {
        display: none;
      }
    }
  }

  #login {
    position: absolute;
    right: 10px;
    top: 50%;
    padding: 5px 15px;
    font-size: 13px;
    border: 1px solid #fff;
    transform: translateY(-50%);
    font-weight: bold;
  }

  .scroll header {
    background: $blue;
    border-bottom: 1px solid transparent;
    .logo {
      width: 135px;
      span {
        background: none;
        background-position: top left;
        img {
          opacity: 1;
        }
      }
    }
  }

  header {
    height: 54px;
    background: $blue;

    &.client {
      .logo {
        strong {
          display: none;
        }
      }
    }

    .logo {
      display: block;
      width: 135px;
      margin: 0 auto;
      top: 0;
      float: none;

      span {
        width: 100%;

        padding: 0;
        float: none;
      }
    }
    .wrap {
      padding: 15px 20px;
    }
    nav {
      background-color: #103242;
      left: 0;
      right: 0;
      top: 54px;
      padding: 0 0 10px;
      width: 100%;
      z-index: 900;
      overflow: hidden;
      transition: all 0.5s ease-out;
      -webkit-overflow-scrolling: touch;
      transform: translate(0);
      border: 0;
      height: 0;
      position: fixed;
      padding: 0;

      ul {
        padding-top: 20px;
        li {
          display: block;
          text-align: left;
          float: none;
          margin: 0 !important;

          a {
            padding: 10px 15px;
            border-bottom: 0 !important;
            font-weight: bold;
            color: #fff !important;
            &.login {
              display: none;
            }

            &.active {
              color: $green !important;
              &:after {
                display: none;
              }
            }
          }

          &:hover {
            ul {
              border: 0;
              height: auto !important;
              padding: 0 0 10px 15px;
            }
          }

          ul {
            padding: 0 0 10px 15px;
            position: static;
            background: transparent;
            overflow: hidden;
            height: auto;
            width: auto !important;
            margin-left: 0 !important;
            text-align: left;
            li {
              a {
                text-align: left;
                color: rgba(255, 255, 255, 0.5) !important;
                font-weight: normal;
              }
            }
          }

          &.btnNav {
            padding: 10px 0 10px 15px !important;
            &:hover {
              span {
                background: none;
                padding: 0;
                ul {
                  background: none;
                  margin: 0;
                  height: auto;
                  padding: 0;
                }
              }
            }
            span {
              border: 0;
              padding: 0;
              strong {
                text-align: left;
                font-weight: bold;
                color: #fff;
                padding-bottom: 10px;
                &:after {
                  display: none;
                }
              }
              ul {
                padding: 0;
                li {
                  padding-bottom: 5px;

                  a {
                    display: block;
                    border-radius: 4px;
                    border: 1px solid $green !important;
                    width: 90px;
                    background: rgba(12, 208, 167, 0.75);
                    color: #fff !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  #banner.secondary.page-provider {
    padding-top: 19px;
    display: none;
  }

  .provider {
    flex-wrap: wrap;
    div.col {
      width: 100%;
      order: 2;
      padding: 30px;
      &.photo {
        order: 1;
        padding: 0;
        min-height: 150px;
        background-position: top center;
      }
    }
    .content {
      float: none !important;
      max-width: none;
    }
  }

  .three-col {
    &.audiences {
      .col {
        width: 100%;
        text-align: left;
        padding: 30px;
        img {
          float: left;
          width: 100px;
          height: auto;
        }

        .btn,
        p,
        h3 {
          position: static;
          transform: none;
          margin-left: 130px;
        }

        .btn {
          display: block;
          text-align: center;
        }
      }
    }
  }

  section.p30 {
    padding: 15px 0;
  }

  .profile {
    width: 200px;
    height: 200px;
  }

  .container {
    flex-wrap: wrap;
    .copy {
      width: 100%;
      text-align: center;
      padding: 0 0 20px;
      font-size: 16px;
      line-height: 24px;
    }
    .content {
      padding: 0;
      .photo {
        width: 100%;
      }
    }
  }

  .three-col.stats {
    margin: 0 0 30px;
    .col {
      width: 50%;

      &:nth-child(3) {
        border-right: 1px solid #d1d1d1;
      }

      &:nth-child(4) {
        border-bottom: 1px solid #d1d1d1;
      }

      &:nth-child(2),
      &:nth-child(4) {
        border-right: 0;
      }
    }
  }

  .intro {
    .copy {
      float: none;
      width: 100%;
    }
    .vid {
      width: 100%;
      float: none;
      padding-top: 10px;
    }
    &.computer {
      img {
        width: 100%;
        margin-top: 10px;
      }
    }
  }

  .jobs {
    h3 {
      font-size: 16px;
    }
    .whr-group {
      font-size: 18px;
      padding: 0;
    }

    .whr-items .whr-item {
      width: 50%;
      padding: 20px;
    }
  }

  .three-col.values {
    flex-wrap: wrap;
    .col {
      width: 100%;
      padding: 10px 0 !important;
      box-sizing: border-box;
      text-align: left;
      div {
        padding: 25px 40px;
        &:hover {
          &:before {
            border-width: 8px;
          }
        }
      }
      img {
        display: block;
        float: left;
        width: 80px;
      }

      h3,
      p {
        margin-left: 110px;
      }
    }
  }

  .two-col {
    .col {
      width: 100% !important;
    }
    &.pad {
      .col {
        padding: 0 0 20px;
      }
    }
  }

  .accordion {
    padding-top: 20px;
    width: 100%;
    float: none;
  }

  #phone {
    margin: 0;
  }

  #laptop {
    width: 100%;
    .device {
      height: auto !important;
      .container {
        content: "";
        display: block;
        padding-top: 60%;
      }
    }
  }

  .w60 {
    width: 100%;
  }

  .four-col.team {
    .col {
      width: 50%;
      padding-bottom: 30px;
    }
  }

  .resultsMatter {
    .col {
      order: 2;
      &.statFirst {
        order: 1;
        padding-bottom: 20px;
      }
    }
  }

  #lightUp {
    footer {
      padding-bottom: 80px;
    }
    #banner {
      height: 300px;
      padding-top: 54px;
      padding-bottom: 0;

      h1 {
        font-size: 50px;
        line-height: normal;
        max-width: 250px;
      }
    }

    .intro h3,
    #accordion h2,
    .benefits h2 {
      font-size: 26px;
      line-height: normal;
    }

    .intro {
      p {
        font-size: 16px;
        line-height: 24px;
      }

      .btn {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 1000;
        text-align: center;
        border-radius: 0;
        padding: 20px 0;
        width: 100%;
      }
    }

    #getStartedButtonSecondary {
      display: none;
    }

    .benefits {
      h4 {
        max-width: none;
        margin: 0 0 0 150px;
        text-align: left;
      }
      .three-col ul {
        padding: 0 0 0 20px;
        margin: 0 0 0 150px;
      }
    }
  }

  // Client Pages
  header.client {
    height: 57px !important;
    &.partner,
    &.partner.affu {
      .logo {
        margin: 0;
        width: 200px;
        span {
          width: 200px;
        }
      }
    }

    &.partner.fidelis,
    &.partner.sphp {
      .logo {
        margin: 0;
        width: 315px;
        span {
          width: 315px;
        }
      }
    }

    &.partner.albanymed {
      .logo {
        margin: 0;
        width: 275px;
        span {
          width: 275px;
        }
      }
    }

    &.partner.albanymedteam {
      .logo {
        margin: 0;
        width: 160px;
        span {
          width: 160px;
        }
      }
    }

    .logo {
      margin: 0;
      width: 160px;
      span {
        width: 160px;
        background-size: 100%;
        img {
          opacity: 0;
        }
      }
    }
    .btn {
      font-size: 14px;
      padding: 8px 25px;
    }
  }
  #banner.client {
    height: 300px;
    h1 {
      br {
        display: block;
      }
    }
    &.sphp {
      margin-top: 50px;
      h1 {
        font-size: 40px;
        line-height: 40px;
        padding-bottom: 10px;
      }
      h3 {
        font-size: 22px;
      }
    }
  }

  .clientIntro {
    .btn {
      font-size: 18px;
      padding: 16px 45px;
    }
    .copy {
      margin-left: 210px;
      h4 {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }

  .three-col {
    &.steps {
      //       max-width: 400px;
      margin: 0 auto;
      .col {
        //         padding: 30px;
        width: 50%;
        h3 {
          font-size: 20px;
        }

        img {
          height: 45px;
        }
      }
    }
  }

  .clientPages {
    .two-col {
      h3 {
        padding-bottom: 10px;
      }
      &.pad .col {
        padding: 20px !important;
      }
    }
  }

  // PCP
  #banner.secondary.pcp h1 {
    padding: 0;
    width: 65% !important;
    font-size: 26px;
  }

  .intro.pcp {
    .copy,
    .vid {
      width: auto;
      padding: 0;
      order: 2;
    }
    .copy {
      padding-bottom: 30px;
    }
    .vid {
      order: 1;
    }
  }

  #calc {
    .wrap {
      flex-wrap: wrap;
      padding: 30px 0;
      div {
        width: 100%;
        padding: 10px 0;
      }
      .param {
        top: -6px;
      }
    }
  }

  .pcp-benefits {
    .two-col {
      .col {
        width: 50%;

        .icons {
          flex-wrap: wrap;
          .icon {
            width: 100%;
            padding: 0 0 25px 0;
          }
        }
      }
    }
  }

  #cta.pcp {
    h3 {
      font-size: 26px;
    }
    p {
      font-size: 16px;
      line-height: 24px;
    }
  }

  #accordion.pcp .container ul.checks {
    width: 100%;
    max-width: 600px;
    margin: 20px auto 0;
  }

  .standardized {
    .two-col.pad {
      padding: 0;
      .col {
        padding: 10px 0 !important;
        max-width: 550px;
        margin: 0 auto;
      }
    }
    .callout {
      font-size: 16px;
    }
  }

  .event {
    margin-top: 56px;
    .details {
      margin: 0 -15px;
      div {
        padding: 15px;
      }
    }
  }

  .two-col.divide {
    .col {
      padding: 0 0 15px 0;
      &:last-child {
        padding: 0;
      }
      ul.disc li {
        padding: 0 0 15px;
      }
    }
  }

  #banner.secondary.hcp {
    margin-top: 57px;
    padding: 30px 0 30px;
  }
}

@media (max-width: 650px) {
  body {
    font-size: 15px;
    line-height: 20px;
  }

  .subhead {
    font-size: 28px;
    line-height: 32px;
  }
  .profile {
    float: none;
    width: 150px;
    height: 150px;
    margin: 10px auto 0;
  }

  footer {
    img {
      margin: 30px auto 20px;
      width: 135px;
    }
    .links {
      float: none;
      padding: 0;
    }
    ul {
      overflow: hidden;
      text-align: center;
      padding-top: 0;
      justify-content: center !important;
      &.policies {
        flex-wrap: wrap;
        padding-bottom: 30px;
        li {
          border: 0;
          padding: 5px 0;
        }
      }
    }
    address {
      text-align: center;
      p {
        max-width: 205px;
        margin: 0 auto;
        padding: 5px;
      }
      p.copyright {
        padding-top: 10px;
        position: static;
      }
    }
  }

  blockquote {
    padding: 20px 0 70px;
  }

  #banner {
    h1 {
      font-size: 30px;
      line-height: 32px;
    }
    &.secondary {
      padding: 85px 0 10px;
      h1 {
        font-size: 28px;
        line-height: 30px;
      }

      &.hcp {
        background-position: center right -250px;
        &:after {
          display: block;
          background: rgba(255, 255, 255, 0.85);
          mix-blend-mode: inherit;
        }
        .wrap {
          div {
            width: 100%;
          }
        }
      }
    }
  }

  .btn {
    padding: 12px 20px;
    font-size: 16px;
    &.sm {
      padding: 8px 15px;
      font-size: 14px;
    }
  }

  h2 {
    font-size: 26px;
    line-height: 30px;
  }

  section.p60 {
    padding: 30px 0;
  }

  #cta {
    h3 {
      max-width: none;
      font-size: 20px;
      line-height: normal;
      padding-bottom: 20px;
    }
    .btn {
      position: static;
      transform: translate(0);
      width: auto;
      background: $blue;
    }

    .btns {
      position: static;
      transform: translate(0);
    }
    &.pcp {
      .btn {
        background: #fff;
      }
    }
  }

  .timeline {
    max-width: 450px;
    width: 100%;
  }

  .three-col,
  .four-col,
  .four-col.team {
    .col {
      width: 100%;
    }
  }

  .team {
    max-width: 450px;
    margin: 0 auto;
    .col {
      padding: 0 0 30px;
      h3 {
        font-size: 20px;
      }
    }
  }

  .single.post {
    padding-top: 60px;
    h1 {
      font-size: 28px;
    }
    h3 {
      font-size: 22px;
    }
    .btn.back {
      font-size: 14px;
      background-size: 14px;
    }
    .cdphpPhoto {
      caption {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 40px;
      }
    }
  }

  .news {
    .article {
      width: 50%;
      .border div h3 {
        font-size: 18px;
      }
    }
  }

  .panelists {
    h2 {
      font-size: 26px;
      line-height: 30px;
      padding: 0 0 20px;
      max-width: 450px;
      margin: 0 auto;
    }
  }

  #cta.lg h3 {
    width: 100%;
    max-width: 100%;
  }

  .event {
    h1 {
      padding: 10px 0 20px;
      font-size: 30px;
      font-weight: 700;
      padding-bottom: 20px;
      line-height: 35px;
    }
    h5 {
      font-size: 16px;
      line-height: 22px;
    }

    .two-col {
      .col {
        width: 100% !important;
        padding: 0 !important;
      }
    }

    .details {
      em {
        font-size: 14px;
      }
      strong {
        font-size: 17px;
      }
      div {
        img {
          width: 28px;
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .clientIntro {
    .btn {
      float: none;
      margin: -60px auto 20px;
      display: block;
      position: relative;
      z-index: 10;
    }
    .copy {
      margin: 0;
    }
  }

  .headline {
    font-size: 16px;
    line-height: 26px;
    text-align: left;
  }

  .callout {
    font-size: 16px;
  }

  .three-col.steps {
    max-width: 400px;
    margin: 0 auto;
    .col {
      width: 100%;
      padding: 10px 20px;
    }
  }

  .clientPages,
  .teen {
    .btn {
      text-align: center;
      width: 100%;
      box-sizing: border-box;
    }
  }
  section.clientPages {
    padding: 20px 0;
    .two-col.pad {
      .col {
        padding: 0 0 20px !important;
      }
    }
    .pcta {
      padding: 0 0 30px;
    }
    .disclaimer {
      padding-bottom: 0;
      font-size: 14px;
    }
  }

  html {
    background: #fff;
  }

  header.client {
    border-bottom: 1px solid #e0e0e0;
    .btn {
      right: 10px;
    }
    &.sphp {
      .btn {
        display: none;
      }
    }
  }

  #banner.client {
    margin-top: 57px;
    padding: 0;
    height: 420px;

    h1 {
      padding: 0;
      font-size: 28px;
      line-height: 32px;
      letter-spacing: 0.4px;
    }

    &.sphp {
      background-color: #ededed;
      padding-top: 0;
      .slick-slider {
        top: 100px;
        height: auto;
        bottom: 0;
      }
      h1 {
        font-size: 30px;
        line-height: 30px;
      }
      h3 {
        font-size: 20px;
      }
    }

    .slick-slider {
      &.slick-initialized .slick-slide {
        div {
          #bg01 {
            background: url("./assets/banner-clients-01-mobile.jpg") no-repeat
              bottom right;
            background-size: 100%;
          }
          #bg02 {
            background: url("./assets/banner-clients-02-mobile.jpg") no-repeat
              bottom right;
            background-size: 100%;
          }
          #bg03 {
            background: url("./assets/banner-clients-03-mobile.jpg") no-repeat
              bottom right;
            background-size: 100%;
          }
          #bg04 {
            background: url("./assets/banner-clients-04-mobile.jpg") no-repeat
              bottom right;
            background-size: 100%;
          }
          #bg05 {
            background: url("./assets/banner-clients-05-mobile.jpg") no-repeat
              bottom right;
            background-size: 100%;
          }
          #bg06 {
            background: url("./assets/banner-clients-06-mobile.jpg") no-repeat
              bottom right;
            background-size: 100%;
          }
          #bg07 {
            background: url("./assets/banner-clients-07-mobile.jpg") no-repeat
              bottom right;
            background-size: 100%;
          }

          #bg-hero-01,
          #bg-hero-03,
          #bg-hero-04 {
            background-position: top right -40px;
            background-size: auto 100%;
          }

          #bg-hero-02 {
            background-position: top right -100px;
            background-size: auto 100%;
          }
        }
      }
    }
  }

  .pcp-benefits {
    .two-col {
      .col {
        width: 100%;
        padding: 0 0 30px;
        &:last-child {
          padding: 0;
        }
      }
    }
  }

  #cta.pcp {
    img {
      display: none;
    }
    .wrap {
      div {
        margin-left: 0;
      }
    }
    &.hcp {
      img {
        display: block;
        width: 120px;
        margin: 0 auto;
        float: none;
        position: static;
        transform: none;
      }
      .wrap {
        div {
          margin: 20px 0 0;
        }
      }
    }
  }

  #accordion.laptop #laptop {
    margin-top: 10px;
  }
}

@media (max-width: 500px) {
  #banner.home {
    background: url("./assets/provider-photo-behavioral.jpg") no-repeat 0 0;
    background-size: cover;
    .rh5v-DefaultPlayer_component {
      display: none;
    }

    &:after {
      position: absolute;
      content: "";
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.7);
      mix-blend-mode: multiply;
    }
  }
  h3,
  .introHead {
    font-size: 22px;
  }

  .subhead {
    font-size: 24px;
    line-height: 28px;
  }

  .heading {
    font-size: 16px;
    margin-bottom: 0;
  }

  .three-col.audiences .col {
    padding: 20px 0;
    img {
      float: none;
      margin-bottom: 10px;
    }
    .btn,
    p,
    h3 {
      margin: 0;
      text-align: center;
    }
    .btn {
      margin: 0 auto;
    }
    h3 {
      padding-bottom: 10px;
      font-size: 20px;
    }
  }
  section.p30 {
    padding: 0;
  }
  section.p60 {
    padding: 15px 0;
  }

  .three-col {
    &.stats {
      .col {
        padding: 5px 0;
        width: 100%;
        border: 0 !important;
        border-bottom: 1px solid #d1d1d1 !important;
        img {
          display: block;
          margin: 0 auto;
        }
      }
    }
  }

  .jobs {
    h2 {
      padding: 10px 0 30px;
    }
    h3 {
      font-size: 15px;
    }

    .whr-group {
      &:after {
        width: 25px;
        margin: 5px 0 8px;
      }
    }

    ul {
      li {
        font-size: 12px;
      }
    }

    .whr-items {
      margin-bottom: 30px;
      .whr-item {
        width: 100%;
        padding: 10px 20px;
      }
    }
  }

  .three-col.values {
    flex-wrap: wrap;
    .col {
      width: 100%;
      padding: 10px 0 !important;
      box-sizing: border-box;
      text-align: left;
      div {
        padding: 20px 20px;
        &:hover {
          &:before {
            border-width: 8px;
          }
        }
      }
      img {
        display: block;
        float: left;
        width: 60px;
      }

      h3,
      p {
        margin-left: 90px;
      }
      h3 {
        padding-bottom: 5px;
      }
    }
  }

  .news {
    .article {
      width: 100%;
      .border div h3 {
        font-size: 16px;
      }
    }
  }

  #lightUp {
    #banner {
      height: 350px;
      padding-bottom: 0;

      &.prescribers {
        height: 350px;
        &:after {
          display: none;
        }
        .wrap {
          background: #002238 url("./assets/bg-prescriber-hero-mobile.jpg")
            no-repeat top center;
          background-size: 100%;
        }
      }

      h1 {
        font-size: 40px;
        line-height: normal;
        max-width: 200px;
        top: auto;
        bottom: 0;
        transform: translate(-50%, 0);
        padding-bottom: 20px;
      }

      .slider {
        .slide {
          &.campaign01 {
            background: transparent
              url("./assets/bg-campaign-hero-01-mobile.jpg") no-repeat top
              center;
            background-size: 100%;
          }
          &.campaign02 {
            background: transparent
              url("./assets/bg-campaign-hero-02-mobile.jpg") no-repeat top
              center;
            background-size: 100%;
          }
          &.campaign03 {
            background: transparent
              url("./assets/bg-campaign-hero-03-mobile.jpg") no-repeat top
              center;
            background-size: 100%;
          }
        }
      }
    }

    .benefits {
      h2 {
        padding-bottom: 20px;
      }

      h4,
      .three-col ul {
        margin: 0;
      }

      h4 {
        text-align: center;
      }
    }

    #accordion .item h4 {
      font-size: 22px;
      line-height: 28px;
      &:after {
        top: 3px;
      }
    }
  }

  section.single.privacy {
    padding-top: 60px;
    h1 {
      font-size: 30px;
      padding-bottom: 20px;
      margin-bottom: 40px;
    }
  }

  footer {
    ul li a {
      font-size: 13px;
    }
  }

  .standardized {
    .headline {
      margin: 0;
    }
    .two-col .col img {
      height: 70px;
    }
  }

  .pcp-benefits .two-col .col {
    max-width: 280px;
    margin: 0 auto;
    padding: 0 0 10px;
  }

  #banner.secondary.pcp {
    padding: 85px 0 25px;
    &:after {
      background: rgba(0, 0, 0, 0.7);
    }
    h1 {
      width: 100% !important;
      font-size: 22px;
    }
  }

  #accordion.pcp h2,
  .standardized h2 {
    font-size: 22px;
    line-height: 28px;
  }

  .intro.pcp {
    padding-top: 0;
    padding-bottom: 10px;
    .copy {
      .btn {
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        margin: 0 0 10px;
      }
    }
  }

  #cta.pcp {
    text-align: center;
    padding: 10px 0;
    h3 {
      text-align: center;
    }
    p {
      font-size: 18px;
      line-height: 25px;
    }
  }

  .event {
    .details {
      margin: 0;
      div {
        padding: 10px 0;
        width: 100%;
      }
    }
  }

  #cta.lg {
    h3 {
      font-size: 18px;
    }
    .btn {
      padding: 10px 0;
      width: 100%;
      box-sizing: border-box;
    }
  }

  .clientPages blockquote {
    margin: 0 0 10px;
  }
}

@media (max-width: 450px) {
  header.client {
    &.partner.albanymed,
    &.partner.amcemployees,
    &.partner.fidelis,
    &.partner.sphp {
      .logo {
        width: 275px;
        margin: 0 auto;
        span {
          width: 275px;
        }
      }
      .btn {
        display: none;
      }
    }
  }
}

@media (max-width: 380px) {
  #lightUp {
    #accordion .item h4 {
      font-size: 20px;
      line-height: 28px;
      &:after {
        top: 3px;
      }
    }
  }
}

@media (max-width: 320px) {
  #lightUp {
    #accordion .item h4 {
      font-size: 18px;
      line-height: 26px;
      &:after {
        top: 4px;
      }
    }
  }
}

#ptsd {
  .disc {
    padding-bottom: 20px;
    li {
      padding: 0 0 10px;
    }
  }
}

.insurance-image-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px; /* Adjust the gap between images as needed */
  padding: 10px;
  box-sizing: border-box;
}

.insurance-image-list img {
  height: 80px; /* Adjust as needed */
  margin: 0 10px;
  object-fit: cover;
  border-radius: 10px;
}

.insurance-image-list img:last-child {
  margin-right: 0;
}

.insurance-image-list img:first-child {
  margin-left: 0;
}